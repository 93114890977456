import React, { useLayoutEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import useSWR from "swr";

import { STEP_LABELS } from "./const";
import { LOCATION_KEY, SWR_KEYS } from "@/const";
import { useQuery, useStores } from "@/hooks";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { Box, CircularProgress } from "@mui/material";
import Stepper from "@/components/Stepper";
import Group
  from "@/pages/EditPlanningGroup/components/Group";
import DistributionStep
  from "@/pages/EditPlanningGroup/components/DistributionStep";
import qs from "qs";

const EditPlanningGroupPage: React.FC = () => {
  const { id } = useParams();
  const {
    headerStore,
    api,
  } = useStores();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data,
    isLoading
  } = useSWR(
    id ? SWR_KEYS.getPlanningPlotById(id) : null,
    () => api.planning.getPlanningPlotById(id as string),
  );

  const query = useQuery<any>();

  const str = qs.stringify(
    {
      ...query,
      keySidebar: "planning",
      tab: "GSUDetail",
      keyDetail: "GSUDetail",
    },
    { arrayFormat: "comma" },
  );

  const handleBack = () => {
      if (location.key !== LOCATION_KEY.DEFAULT) {
      navigate({
        pathname: ROUTES_PATHS.planning,
        search: `?${str}`
      });
      return
    } else
      navigate({
        pathname: ROUTES_PATHS.planning
      });
  };

  useLayoutEffect(() => {
    headerStore.setProps({
      title: "Редактировать группы",
      subtitle: data?.plot?.name,
      handleBack
    });
    headerStore.setHeader(VARIANT_HEADER.DEFAULT);
  }, [data]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Stepper handleClose={handleBack}
    >
      <Stepper.StepContainer
        label={STEP_LABELS.GROUPS}
      >
        <Group />
      </Stepper.StepContainer>
      <Stepper.StepContainer
        label={STEP_LABELS.DISTRIBUTION}
      >
        <Stepper.Step handleSubmit={handleBack}>
          <DistributionStep trialType={data.plan.trial_type}
            year={data.plan.year}
            planPlotId={data.id}
            cultivarId={data.plan.cultivar} />
        </Stepper.Step>
      </Stepper.StepContainer>
    </Stepper>
  );
};

export default observer(EditPlanningGroupPage);
