import { Formik } from "formik";
import React from "react";
import AddVarietyTrialForm from "./components/AddVarietyIndicatorForm";
import { FIELD_NAME, IFormik, serializedValues, validationSchema } from "./const";
import { useStores } from "@/hooks";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { EditIcon } from "@/components/icons";
import theme from "@/theme";
import { SWR_KEYS } from "@/const";
import { IVarietyTrial } from "@/api/interfaces/responses";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    indicatorType: string;
    varietyId: number;
    data?: IVarietyTrial;
  };
}

const AddVarietyTrial: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const { varietyId, data, } = modalProps;
  const { api, toastStore, modalStore, swrStore } = useStores();

  const defaultValues = {
    [FIELD_NAME.VARIETY]: varietyId,
    [FIELD_NAME.PLOT]: null,
    [FIELD_NAME.YEAR_OF_TESTING]: undefined,
    [FIELD_NAME.TYPE_TRIAL]: "oos",
    [FIELD_NAME.SPECIALIZATION]: undefined,
    [FIELD_NAME.GROUP]: undefined,
    [FIELD_NAME.IS_STANDARD]: undefined,
    [FIELD_NAME.LEAST_SIGNIFICANT_DIFFERENCE]: undefined,
  }

  const values = data ? serializedValues(data) : defaultValues

  const mutateList = swrStore.mutators[SWR_KEYS.getVarietyTrialList()];

  const fetcher = (
    payload: IFormik
  ) => {
    if (!data) return api.regulatoryInfo.postVarietyTrial(payload)
    return api.regulatoryInfo.patchVarietyTrial(payload, data.id);
  }

  const handleSubmit = (value: IFormik, { setSubmitting }) => {
    setSubmitting(true)

    const payloadPost = {
      [FIELD_NAME.YEAR_OF_TESTING]: value.year_of_testing,
      [FIELD_NAME.TYPE_TRIAL]: value.type_trial,
      [FIELD_NAME.PLOT]: value.plot?.id,
      [FIELD_NAME.SPECIALIZATION]: value.specialization,
      [FIELD_NAME.GROUP]: value.group,
      [FIELD_NAME.IS_STANDARD]: value.is_standard,
      [FIELD_NAME.LEAST_SIGNIFICANT_DIFFERENCE]: value.least_significant_difference,
      [FIELD_NAME.VARIETY]: value.variety,
    }

    fetcher(payloadPost as IFormik)
      .then(() => {
        mutateList && mutateList()
        handleClose();
        setSubmitting(false)
      })
      .catch((e) => {
        toastStore.createToast(e);
      })
      .finally(() => {
        modalStore.close();
      });
  }

  return (

    <Box>
      <Stack
        direction={"row"}
        alignItems={"flex-end"}
        gap={2}
        pb={2}
        p={3}
      >
        {modalProps.data
          ? <Typography color={theme.palette.blackAndWhite.gray}>
            <EditIcon />
          </Typography>
          : null}
        <Box>
          <Typography variant="h2" fontSize={10} color={theme.palette.blackAndWhite.gray}>
            Пройденное испытание
          </Typography>
          <Typography variant="h2" fontSize={24}>
            {modalProps.data ? "Редактировать запись" : "Добавить запись"}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      <Formik<IFormik>
        initialValues={values as IFormik}
        onSubmit={handleSubmit}
        validateOnChange
        validationSchema={validationSchema}
        enableReinitialize
        component={() =>
          <AddVarietyTrialForm
            modalProps={modalProps}
            handleClose={handleClose} />
        }
      >
      </Formik>
    </Box>
  )
}

export default AddVarietyTrial;
