import React from "react";
import { FastField } from "formik";
import Typography from "@mui/material/Typography";
import { FIELDS_NAME } from "@/pages/AddObservation/const";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  FormControlLabel,
  Radio
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePickerField } from "@/components/Fields";
import {
  VegetationStageAutocomplete
} from "@/components/Autocompletes";

interface IProps {
  cultivarId?: number,
}

const DataBlock: React.FC<IProps> = ({ cultivarId }) => {
  return (
    <Box>
      <Stack direction="column" spacing="20px">
        <Typography
          variant="h3">Данные наблюдения</Typography>
        <Box maxWidth={180}>
          <DatePickerField
            name={FIELDS_NAME.OBSERVATION_DATE}
            label="Дата"
            required
            fullWidth
          />
        </Box>
        <VegetationStageAutocomplete
          name={FIELDS_NAME.VEGETATION_STAGE}
          required
          q={{cultivar: cultivarId}}
          />
        <FastField name={FIELDS_NAME.ALLOCATION_NUMBER}>
          {({ field }) => (
            <FormControl>
              <FormLabel>
                Повторения
              </FormLabel>
              <RadioGroup {...field}>
                <Stack direction="row">
                  <FormControlLabel
                    value={1}
                    control={<Radio color="secondary"/>}
                    label="1"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio color="secondary"/>}
                    label="2"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio color="secondary"/>}
                    label="3"
                  />
                  <FormControlLabel
                    value={4}
                    control={<Radio color="secondary"/>}
                    label="4"
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        </FastField>
      </Stack>
    </Box>
  );
};

export default DataBlock;