import React, { useEffect, useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import useSWR from "swr";
import { Box, CircularProgress } from "@mui/material";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { ROUTES, ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";
import {
  IPlanForm,
  mapFormToValue,
  mapValueToForm,
  STEP_LABELS,
  validationSchema,
} from "./const";
import {
  IndicatorFormStep,
  PlanFormStep,
} from "./components";
import { isUndefined } from "@/utils/helpers";
import { KEYS_SIDEBAR_VARIANT_MODULES, SWR_KEYS } from "@/const";
import FormikStepper from "@/components/FormikStepper";

/**
 * Страница добавления плана
 * @param headerTitle - заголовок шапки
 * */

interface IProps {
  headerTitle?: string;
}

const AddPlanningHpPage: React.FC<IProps> = ({
  headerTitle,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { headerStore, api, swrStore } = useStores();

  const { data, isLoading, mutate } = useSWR(
    !isUndefined(id)
      ? SWR_KEYS.getPlanningHpByIdKey(id)
      : null,
    () => api.planning.getPlanningHpById(id as string),
  );

  const handleSubmit = (
    values: IPlanForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);
    const promise = id
      ? api.planning.patchPlanningHp(
        id,
        mapFormToValue(values),
      )
      : api.planning.postPlanningHp(mapFormToValue(values));
    promise
      .then(() => {
        void mutate();
        handleClose();
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const handleClose = () => {
    if (id) {
      navigate(
        `/${ROUTES.planning}/${ROUTES.hp}?planning_hp_id=${id}&keySidebar=${KEYS_SIDEBAR_VARIANT_MODULES.PLANNING_HP}`
      );
    } else {
      navigate({
        pathname: ROUTES_PATHS.planningHp,
      });
    }
  };

  useLayoutEffect(() => {
    headerStore.setProps({
      title: headerTitle,
      id: id,
    });
    headerStore.setHeader(VARIANT_HEADER.ADD_PLAN_HP);
  }, [id]);

  const mutateOrders =
    swrStore.mutators[SWR_KEYS.getOrderList()];

  useEffect(() => {
    mutateOrders && mutateOrders();
  }, []);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <FormikStepper
      initialValues={mapValueToForm(data)}
      onSubmit={handleSubmit}
      handleClose={handleClose}
      enableReinitialize={true}
    >
      <FormikStepper.FormikStep
        label={STEP_LABELS.CULTIVAR}
        validationSchema={validationSchema}
      >
        <PlanFormStep />
      </FormikStepper.FormikStep>

      <FormikStepper.FormikStep label={STEP_LABELS.PLOT}>
        <IndicatorFormStep />
      </FormikStepper.FormikStep>
    </FormikStepper>
  );
};

export default observer(AddPlanningHpPage);
