import { IVarietyTrial } from "@/api/interfaces/responses";
import { TPlanningTrialType } from "@/api/types";
import yup from "@/utils/yup";
import { VALIDATION_MESSAGE_NUMBER } from "../AddEffectivity/utils/const";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    varietyId: number,
    indicatorType: string,
  };
  onSubmit?: () => void;
}

interface ISelectValue {
  id?: number;
  label?: string;
}

export enum FIELD_NAME {
  YEAR_OF_TESTING = "year_of_testing",
  TYPE_TRIAL = "type_trial",
  PLOT = "plot",
  SPECIALIZATION = "specialization",
  GROUP = "group",
  IS_STANDARD = "is_standard",
  LEAST_SIGNIFICANT_DIFFERENCE = "least_significant_difference",
  VARIETY = "variety",
}

export interface IFormik {
  [FIELD_NAME.VARIETY]: number,
  [FIELD_NAME.YEAR_OF_TESTING]: number,
  [FIELD_NAME.PLOT]: ISelectValue | null,
  [FIELD_NAME.TYPE_TRIAL]?: TPlanningTrialType,
  [FIELD_NAME.SPECIALIZATION]?: string,
  [FIELD_NAME.GROUP]?: number,
  [FIELD_NAME.IS_STANDARD]?: boolean,
  [FIELD_NAME.LEAST_SIGNIFICANT_DIFFERENCE]?: string,
}

export const validationSchema = yup.object().shape({
  [FIELD_NAME.YEAR_OF_TESTING]: yup.number().required(),
  [FIELD_NAME.PLOT]: yup.mixed().required(),
  [FIELD_NAME.TYPE_TRIAL]: yup.string(),
  [FIELD_NAME.SPECIALIZATION]: yup.string(),
  [FIELD_NAME.GROUP]: yup.number().max(2147483647).nullable(),
  [FIELD_NAME.IS_STANDARD]: yup.boolean(),
  [FIELD_NAME.LEAST_SIGNIFICANT_DIFFERENCE]: yup
    .number()
    .max(9999)
    .nullable()
    .test('is-decimal', VALIDATION_MESSAGE_NUMBER.text, (value) =>
      value === null ||
      value === undefined ||
      VALIDATION_MESSAGE_NUMBER.regEx.test(value.toString())
    ),
});

export const serializedValues = (data: IVarietyTrial): IFormik => {

  return (
    {
      [FIELD_NAME.YEAR_OF_TESTING]: data.year_of_testing,
      [FIELD_NAME.TYPE_TRIAL]: data.type_trial,
      [FIELD_NAME.PLOT]: data
        ? {
          id: data.plot.id,
          label: data.plot.name
        }
        : null,
      [FIELD_NAME.SPECIALIZATION]: data.specialization,
      [FIELD_NAME.GROUP]: data.group,
      [FIELD_NAME.IS_STANDARD]: data.is_standard,
      [FIELD_NAME.LEAST_SIGNIFICANT_DIFFERENCE]: data.least_significant_difference,
      [FIELD_NAME.VARIETY]: data.variety.id,
    }
  );
}
