import React, {
  Fragment,
  ReactElement,
  useMemo,
  useState,
} from "react";
import useSWR from "swr";
import qs from "qs";
import {
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import BaseTable from "@/apps/Table";
import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";

import {
  IAgriculturalLabourCultivarField,
} from "@/api/interfaces/responses";

import {
  useQuery,
  useStores,
  useRightSidebar
} from "@/hooks";

import { columns } from "../models";
import { isUndefined } from "@/utils/helpers";
import {
  TCultivarFieldKeys,
  ICultivarFieldQueryParams
} from "../interfaces";
import {
  ADD_PLANING_TEXT,
  EMPTY_LIST_PLANING_TEXT,
  EMPTY_LIST_PLANING_TEXT_WITH_FILTERS,
  FIELD_DESTINATION_TYPE,
} from "../const";
import {
  SWR_KEYS,
  KEYS_SIDEBAR_VARIANT_MODULES,
  DOWNLOAD_SEED_ARRIVAL,
} from "@/const";
import { Box, Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Filters from "@/components/Filters";
import { ROUTES } from "@/apps/AppRouter/const";
import useFilters
  from "@/pages/CultivarField/hooks/useFilters";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";

/**
 * Компонент, представляющий таблицу План использования земли.
 * @returns {ReactElement}
 */
const Table = (): ReactElement => {
  const { 
    api, 
    swrStore, 
    queryStringSidebarCollector, 
    userStore, 
    modalStore 
  } =
    useStores();
  const { handleOpen } = useRightSidebar();
  const [, setSearchParams] = useSearchParams();
  const query = useQuery<ICultivarFieldQueryParams>();
  const navigate = useNavigate();
  const canAddCultivarField = userStore.isPermission(PERMISSIONS.agriculturalLabourCultivarField, PERMISSION_CRUD.add);

  const filters = useFilters();

  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orgeringArray);
  const {
    data,
    error,
    isLoading,
    mutate,
  } = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: query?.search,
      field__planting_year: query?.year,
      type_trial: query?.trial_type?.key,
      key: SWR_KEYS.getCultivarField(),
      field__destination__type: FIELD_DESTINATION_TYPE.TRIALS
    },
    api.agriculturalLabour.getCultivarFieldList,
  );

  swrStore.addMutator(SWR_KEYS.getAgriculturalLabourCultivarFieldList(), mutate);

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value: TCultivarFieldKeys[]) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  const isFilters: boolean =
    Object.keys(query).length > 0;

  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   *
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */
  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {
    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD,
      module_id: id,
      trial_type: {
        key: query?.trial_type?.key,
        label: query?.trial_type?.label,
      },
    });

    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD,
      modalProps: {
        id,
        keySidebar: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD,
      },
    });
    setSearchParams(str);
  };

  const handleAdd = () => {
    navigate(`${ROUTES.addCultivarField}`);
  };

  const handleDownloadPlan= () => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_LAND_USE_PLAN, {
      theme: THEME_MODAL.W_555,
      type: DOWNLOAD_SEED_ARRIVAL.JOURNAL,
      title: "План использования земли на ГСУ"
    });
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
        />
        <Stack
          flexDirection={"row"}
          gap={2}
        >
          <Button
            color="blue"
            onClick={handleDownloadPlan}
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
          >
            Документы
          </Button>
          {
            canAddCultivarField && (
              <Button
                sx={{
                  whiteSpace: "nowrap",
                  flexShrink: "0",
                }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAdd}
              >
                Добавить план
              </Button>
            )
          }
        </Stack>
      </Box>
      <BaseTable<IAgriculturalLabourCultivarField, TCultivarFieldKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={ADD_PLANING_TEXT}
        textEmptyTableWithFilter={
          EMPTY_LIST_PLANING_TEXT_WITH_FILTERS
        }
        textEmptyTable={EMPTY_LIST_PLANING_TEXT}
        onRowClick={handleOpenRightSidebar}
      />
    </Fragment>
  );
};

export default Table;
