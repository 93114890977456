import { Box, Typography } from "@mui/material"
import { Formik, Form, FormikHelpers } from "formik"
import React from "react"
import { IFormik, formToValue, validationSchemaAddPlanWithoutBranch } from "../../const"
import FormAddPlan from "../FormAddPlan"
import { useStores } from "@/hooks"
import { useNavigate, useSearchParams } from "react-router-dom"
import { TPlanningTrialType } from "@/api/types"
import { ROUTES_PATHS } from "@/apps/AppRouter/const"
import { VARIANT_MODAL } from "@/apps/Modals/const"
import { TOAST_TYPES } from "@/apps/Toast"
import { errorToString } from "@/utils/helpers"
import { AxiosError } from "axios"

const FormAddPlanWrap = () => {
  const { api, toastStore, modalStore } = useStores();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  const trialTypeString = searchParams.get("trial_type");
  const trialTypeMapping: { [key: string]: TPlanningTrialType } = {
    hp: "hp",
    oos: "oos",
    phyto: "phyto",
    lab: "lab",
  };
  const trialType = trialTypeMapping[trialTypeString as keyof typeof trialTypeMapping] || undefined;

  const serialaizerFormData = (value: IFormik) => {
    const formValue = formToValue(value)
    return {
      ...formValue,
      trial_type: trialType
    }
  }

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<IFormik>
  ) => {
    api.planning
      .postGeneratePlanningPlan(serialaizerFormData(values))
      .then((res) => {
        navigate({
          pathname: ROUTES_PATHS.planning
        });
        modalStore.open(VARIANT_MODAL.SUCCESS_ADD_PLAN, {
          title: 'Результаты создания планов',
          succesDescription: `Создан(ы) план(ы): `,
          failedDescription: `Не удалось создать план(ы): `,
          succesMesage: res.success,
          failedMesage: res.failed,
          success_count: res.success_count,
          failed_count: res.failed_count,
        });
      })
      .catch((error: AxiosError) => {
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: error.response?.data?.error
              ? error.response?.data?.error
              : errorToString(error),
            severity: "error",
          },
        });
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  return (
    <Box p={3}>
      <Typography mb={3} variant="h3">Данные плана</Typography>
      <Formik
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          cultivars: null,
          region: null,
          branches: null,
          plan_date: null,
          year: null,
        }}
        enableReinitialize={true}
        validationSchema={validationSchemaAddPlanWithoutBranch}
      >
        {(formikTools) => {
          return (
            <Form
              noValidate
            >
              <FormAddPlan
                formikProps={formikTools}
                showFieldBranhes={false}
              />
            </Form>
          )
        }}
      </Formik >
    </Box>
  )
}

export default FormAddPlanWrap
