// import { MaximizeTwoTone } from "@mui/icons-material";
import { SxProps, Theme } from "@mui/material/styles";

export const tableWrapperStyle = (theme: Theme): SxProps => ({
  width: "100%",
  padding: theme.spacing(3)
});

export const cellStatusStyle = (
  theme: Theme,
  backgroundColor: string,
  textColor: string
): SxProps => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  padding: "3px 5px",
  color: textColor,
  backgroundColor: backgroundColor,
  borderRadius: "100px",
  minWidth: "100px"
});

export const headerCellStyle = (theme: Theme): SxProps => ({
  whiteSpace: "nowrap",
  color: theme.palette.blackAndWhite.black,
});

export const cellNumberRegions = (theme: Theme): SxProps => ({
  whiteSpace: "nowrap",
  color: theme.palette.blackAndWhite.black,
  width: "60px",
});

export const headerMaterialTypeCellStyle = (theme: Theme): SxProps => ({
  whiteSpace: "nowrap",
  color: theme.palette.blackAndWhite.black,
});

export const cellStatus = (theme: Theme): SxProps => ({
  padding: theme.spacing(2)
});

export const cellNumber = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  color: theme.palette.blackAndWhite.gray
});

export const cellTypes = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  width: "100%"
});

export const cellAuthor = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  whiteSpace: "nowrap"
});

export const nameAuthorCellsStyle = (): SxProps => ({
  display: "flex",
  alignItems: "center",
  gap: "6px"
});

export const textOverflowStyle = (): SxProps => ({
  display: "block",
  textOverflow: "ellipsis",
  wordWrap: "break-word",
  overflow: "hidden",

  maxWidth: "150px"
});

export const cellDate = (theme: Theme): SxProps => ({
  padding: theme.spacing(2)
});

export const buttonDotsStyle = (): SxProps => ({
  width: "24px",
  height: "24px"
});

export const cellButtons = (theme: Theme): SxProps => ({
  color: theme.palette.primary.main,
  width: "min-content"
});


// установи ей flex-end
export const cellButtonsMaterialType = (
  theme: Theme,
): SxProps => ({
  color: theme.palette.primary.main,
  width: '5%'
});


export const cellPointText = (theme: Theme): SxProps => ({
  color: theme.palette.primary.main,
  width: "min-content",
  padding: 1
});
