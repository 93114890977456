import React, { useEffect, useLayoutEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { Formik, FormikHelpers } from "formik";
import useSWR, { useSWRConfig } from "swr";
import { AxiosError } from "axios";
import {
  errorToString,
  isNull,
  isUndefined,
} from "@/utils/helpers";
import { useStores } from "@/hooks";
import {
  initialValues,
  TRIAL_FIELD_NAME,
  validationSchema,
  THpFormik,
} from "./const";
import {
  formToValueSerializer,
  serializeContext,
  valueToFormSerializer,
} from "@/apps/EditTrial/components/HpTrial/utils";
import { Box, CircularProgress } from "@mui/material";
import HpTrialForm
  from "@/apps/EditTrial/components/HpTrial/HpTrialForm";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import {
  LOCATION_KEY,
  SWR_KEYS,
  TRIAL_TYPE,
  HP_TYPE,
} from "@/const";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { TOAST_TYPES } from "@/apps/Toast";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { FormikValues } from "formik/dist/types";

/**
 * Компонент с формой испытания на хп
 * @component
 */
const HpTrial = () => {
  const { statementId, trialId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const swr = useSWRConfig();
  const {
    api,
    headerStore,
    toastStore,
    swrStore,
    modalStore,
  } = useStores();
  const mutateTrials =
    swrStore.mutators[
      SWR_KEYS.getTrialByStatementIdKey(Number(statementId))
      ];
  const contextFetcher = ([, id]: [
    string,
      string | undefined,
  ]) => {
    if (isUndefined(id)) return null;
    return api.statement.getTrialContext(id, "hp");
  };

  const fetcher = ([, id]: [
    string,
      string | undefined,
  ]) => {
    if (isUndefined(id)) return null;
    return api.trial.getTrial(id);
  };

  const { data, isLoading } = useSWR(
    ["getTrial", trialId],
    fetcher,
    {
      onError: (error: AxiosError) => {
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorToString(error),
            severity: "error",
          },
        });
      },
    },
  );
  const { data: contextData, isLoading: isContextLoading } =
    useSWR(
      ["hpTrialContext", statementId],
      contextFetcher,
      {
        onError: (error: AxiosError) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        },
      },
    );

  const onSubmit = (values: THpFormik, helpers: FormikHelpers<FormikValues>) => {
    const serializedValues = formToValueSerializer(values);
    const data = {
      ...serializedValues,
      statement: Number(statementId),
      trial_type: TRIAL_TYPE.HP,
      inspection_address: values.type_hp === HP_TYPE.ACCORDING_EXPERT_ASSESSMENT ? values.inspection_address?.id : null,
      inspections_number: values.type_hp === HP_TYPE.ACCORDING_EXPERT_ASSESSMENT ? Number(values.inspections_number) : null
    };
    const promise = trialId
      ? api.trial.pathTrial(trialId as string, data)
      : api.trial.postHpTrial(data);
    promise
      .then(() => {
        if (location.key !== LOCATION_KEY.DEFAULT) {
          navigate(-1);
        } else
          navigate({
            pathname: ROUTES_PATHS.statements,
          });
        mutateTrials && mutateTrials();
        trialId &&
        swr.mutate(SWR_KEYS.getTrialById(trialId));
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          const errorMessage = errorToString(error);
          helpers.setFieldError('errors', errorMessage)
        }
        if (
          error.response?.data &&
          error.response.data?.surveys
        ) {
          const err = error.response.data.surveys.map(
            (survey) => {
              return {
                [TRIAL_FIELD_NAME.INDICATOR_METHODOLOGY]:
                  Object.values(survey).join(", "),
              };
            },
          );
          helpers.setFieldError(
            TRIAL_FIELD_NAME.SURVEYS,
            err,
          );
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  useLayoutEffect(() => {
    headerStore.setProps({
      title: trialId
        ? "Редактировать испытание"
        : "Добавить испытание",
      type: TRIAL_TYPE.HP,
    });
    headerStore.setHeader(VARIANT_HEADER.EDIT_TRIAL);
  }, [trialId]);

  useEffect(() => {
    if (
      contextData &&
      (contextData.cultivar_usage_types.length === 0 ||
        contextData.cultivar_usage_types?.[0]
          ?.cultivar_groups?.length === 0)
    ) {
      modalStore.open(VARIANT_MODAL.GO_TO_HANDBOOK, {
        title: "Добавить испытание",
        description:
          "Для создания испытания на хозяйственную полезность, внесите необходимые данные в Справочник \"Группы культуры\"",
        withoutCross: true,
      });
    }
  }, [contextData]);

  if (isContextLoading || isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80}/>
      </Box>
    );
  }

  if (
    !statementId ||
    isUndefined(contextData) ||
    isNull(contextData)
  ) {
    return null;
  }

  if (trialId && (isUndefined(data) || isNull(data))) {
    return null;
  }

  const values = trialId
    ? valueToFormSerializer(data)
    : initialValues;

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...values,
        context: serializeContext(contextData),
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
      component={() => <HpTrialForm statementId={statementId} trialId={trialId} />}
    />
  );
};

export default HpTrial;
