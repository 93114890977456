import { resInterface } from "@/api/interfaces";
import yup from "@/utils/yup";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    rateId?: number;
    initialValues?: resInterface.IRate;
  };
  onSubmit?: () => void;
}

export interface TForm {
  [RATE_FIELD_NAME.NAME]: string,
  [RATE_FIELD_NAME.UNIT]: {
    id: number,
    name: string,
  },
}

export enum RATE_FIELD_NAME {
  NAME = 'name',
  UNIT = 'unit',
}


export const mapFormToValue = (values: TForm) => {
  return {
    [RATE_FIELD_NAME.NAME]: values?.name || '',
    [RATE_FIELD_NAME.UNIT]: values?.unit || undefined,
  };
};


/*Validation of the form*/

export const validationSchema = yup.object().shape({
  [RATE_FIELD_NAME.NAME]: yup.string().trim().required(),
  [RATE_FIELD_NAME.UNIT]: yup.mixed().nullable(),
});
