import React, { useMemo } from "react";
import useSWR from "swr";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import {
  Stack,
  IconButton,
  Button,
  CircularProgress
} from "@mui/material";
import {
  FieldArray,
  useFormikContext,
  Field
} from "formik";
import { FIELDS_NAME } from "../../const";
import {
  Close as CloseIcon,
  Add as AddIcon
} from "@mui/icons-material";
import { Box } from "@mui/system";
import theme from "@/theme";
import { useStores, useInfiniteScroll } from "@/hooks";
import Empty from "../Empty";
import {
  isUndefined,
  errorToString,
  isNull
} from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { TRIAL_TYPE, SWR_KEYS } from "@/const";
import { StyledBox } from "../../styles";
import {
  serializeContext,
  serializeSurveys
} from "../../utils";
import Autocomplete from "@/components/Autocomplete";

const Description = () => {
  const { id: statementId } = useParams();
  const { api, toastStore } = useStores();

  const formik = useFormikContext<any>();

  const contextFetcher = ([, id]: [
    string,
      string | undefined,
  ]) => {
    if (isUndefined(id)) return null;
    return api.statement.getTrialContext(id, TRIAL_TYPE.HP);
  };

  const showError = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error",
      },
    });
  };
  const { data: contextData, isLoading: isContextLoading } =
    useSWR(
      ["hpTrialContext", statementId],
      contextFetcher,
      {
        onError: (error: AxiosError) => {
          showError(error);
        },
        revalidateOnMount: true

      },
    );

  const context = useMemo(() => serializeContext(contextData), [contextData]);

  const getKey = (index: number) => ({
    _key: SWR_KEYS.getSurvey(),
    page: index + 1,
    page_size: 100000,
    type_trial: TRIAL_TYPE.HP,
    statement: statementId
  });

  const {
    isLoading,
    mutate
  } = useInfiniteScroll(getKey, api.regulatoryInfo.getSurveyList, {
    revalidateOnMount: true, onSuccess: (data) => {
      if (data.length && data[0].results.length) {
        const values = serializeSurveys(data[0].results);
        void formik.setFieldValue(FIELDS_NAME.SURVEYS, values);
      }
    }
  });

  const handleChangeIndicator = (name: string, index: number) => (_, value) => {
    void formik.setFieldValue(name, value);
    void formik.setFieldValue(
      `${FIELDS_NAME.SURVEYS}.${index}.${FIELDS_NAME.SEVERITY}`,
      "",
    );
  };

  const onClearIndicator = (index: number) => {
    void formik.setFieldValue(
      `${FIELDS_NAME.SURVEYS}.${index}.${FIELDS_NAME.INDICATOR_METHODOLOGY}`,
      "",
    );
    void formik.setFieldValue(
      `${FIELDS_NAME.SURVEYS}.${index}.${FIELDS_NAME.SEVERITY}`,
      "",
    );
  };

  const handleChangeSurvey = (name: string, index: number) => (_, value, reason) => {
    if (reason === "selectOption") {
      formik.setSubmitting(true);
      const survey = formik.values[FIELDS_NAME.SURVEYS][index];

      const payload = {
        statement: Number(statementId),
        indicator_methodology: survey[FIELDS_NAME.INDICATOR_METHODOLOGY].id,
        severity: value.severity,
        type_trial: TRIAL_TYPE.HP
      };

      const promise = survey?.id ? api.regulatoryInfo.patchSurveyById(survey.id, payload) : api.regulatoryInfo.postSurvey(payload);
      promise.then((data) => {
        const values = formik.values[FIELDS_NAME.SURVEYS].map((el, i) => i === index ? {
          ...el,
          id: data.id,
          [FIELDS_NAME.SEVERITY]: {
            id: value.severity,
            name: value.severity,
            label: value.severity
          }
        } : el);
        void formik.setFieldValue(FIELDS_NAME.SURVEYS, values);
        mutate();
      }).catch((error) => {
        showError(error);
      }).finally(() => {
        formik.setSubmitting(false);
      });
    } else if (reason === "clear") {
      formik.setSubmitting(true);
      const survey = formik.values[FIELDS_NAME.SURVEYS][index];

      const payload = {
        statement: Number(statementId),
        indicator_methodology: survey[FIELDS_NAME.INDICATOR_METHODOLOGY].id,
        severity: "",
        type_trial: TRIAL_TYPE.HP
      };

      const promise = survey?.id ? api.regulatoryInfo.patchSurveyById(survey.id, payload) : api.regulatoryInfo.postSurvey(payload);
      promise.then((data) => {
        const values = formik.values[FIELDS_NAME.SURVEYS].map((el, i) => i === index ? {
          ...el,
          id: data.id,
          [FIELDS_NAME.SEVERITY]: {
            id: value.severity,
            name: value.severity,
            label: value.severity
          }
        } : el);
        void formik.setFieldValue(FIELDS_NAME.SURVEYS, values);
        mutate();
      }).catch((error) => {
        showError(error);
      }).finally(() => {
        formik.setSubmitting(false);
      });
    } else {
      void formik.setFieldValue(name, value);
    }
  };

  const handleRemove = (index: number) => {
    const survey = formik.values[FIELDS_NAME.SURVEYS][index];
    if (survey.id) {
      formik.setSubmitting(true);
      api.regulatoryInfo.deleteSurveyById(survey.id).then(() => {
        const values = formik.values[FIELDS_NAME.SURVEYS].filter((_, i) => i !== index);
        void formik.setFieldValue(FIELDS_NAME.SURVEYS, values);
      }).catch((error) => {
        showError(error);
      }).finally(() => {
        mutate();
        formik.setSubmitting(false);
      });
    } else {
      const values = formik.values[FIELDS_NAME.SURVEYS].filter((_, i) => i !== index);
      void formik.setFieldValue(FIELDS_NAME.SURVEYS, values);
    }
  };

  if (isContextLoading || isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80}/>
      </Box>
    );
  }

  if (
    !statementId ||
    isUndefined(contextData) ||
    isNull(contextData)
  ) {
    return null;
  }

  return (
    <Box>
      {context?.indicators?.length ? (
        <FieldArray name={FIELDS_NAME.SURVEYS}>
          {({ name, form, push }) => {
            return (
              <Stack
                direction="column"
                spacing="20px"
              >
                {form.values[name].map((_, index) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    key={index}
                    sx={{
                      marginRight: "-40px !important",
                    }}
                  >
                    <StyledBox>
                      <Field
                        name={`${name}.${index}.${FIELDS_NAME.INDICATOR_METHODOLOGY}`}
                      >
                        {({ field, meta }) => (
                          <Autocomplete
                            label="Признак"
                            {...field}
                            onChange={(event, child) => handleChangeIndicator(field.name, index)(event, child)}
                            onClear={() => onClearIndicator(index)}
                            data={Array.isArray(context.indicators) ? context.indicators : []}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                            filter={true}
                            disabled={formik.isSubmitting}
                            fullWidth={true}
                          />
                        )}
                      </Field>
                      <Field
                        name={`${name}.${index}.${FIELDS_NAME.SEVERITY}`}
                      >
                        {({ field, meta }) => (
                          <Autocomplete
                            label="Значение признака"
                            {...field}
                            onChange={(...args) => handleChangeSurvey(field.name, index)(...args)}
                            data={Array.isArray(context.indicators_variants[formik.values.surveys?.[index]?.indicator_methodology?.id]) ? context.indicators_variants[formik.values.surveys[index].indicator_methodology.id] : []}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                            getLabel={el => el.severity}
                            filter={true}
                            fullWidth
                            disabled={
                              !formik.values[
                                FIELDS_NAME
                                  .SURVEYS
                                ][index][
                                FIELDS_NAME
                                  .INDICATOR_METHODOLOGY
                                ] || formik.isSubmitting
                            }
                          />
                        )}
                      </Field>
                    </StyledBox>
                    <IconButton
                      disabled={formik.isSubmitting}
                      sx={{
                        color: theme.palette.red.main,
                      }}
                      onClick={() => handleRemove(index)}
                    >
                      <CloseIcon/>
                    </IconButton>
                  </Stack>
                ))}
                <Box>
                  <Button
                    variant="outlined"
                    color="blue"
                    startIcon={<AddIcon/>}
                    fullWidth
                    onClick={() =>
                      push({
                        [FIELDS_NAME.INDICATOR_METHODOLOGY]:
                          "",
                        [FIELDS_NAME.SEVERITY]:
                          "",
                      })
                    }
                  >
                    Добавить признак
                  </Button>
                </Box>
              </Stack>
            );
          }}
        </FieldArray>
      ) : (
        <Empty/>
      )}
    </Box>
  );
};

export default Description;