import React, { useEffect } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Box,
  Divider,
  IconButton,
  Menu,
  SxProps,
  Typography,
} from "@mui/material";
import { EditIcon, DeleteIcon } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import theme from "@/theme";
import { SWR_KEYS } from "@/const";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

export const cellRemarkStyle = (
  backgroundColor: string,
  textColor: string,
): SxProps => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  padding: "3px 5px",
  color: textColor,
  backgroundColor: backgroundColor,
  borderRadius: "100px",
  minWidth: "100px",
  maxWidth: "100px",
});

export const REMARK_TYPE_COLORS = {
  revision: {
    background: theme.palette.blackAndWhite.grayLight,
    text: theme.palette.blackAndWhite.black,
  },
  refusal: {
    background: theme.palette.blackAndWhite.grayLight,
    text: theme.palette.blackAndWhite.black,
  },
};

/**
 * @component type remark
 * * компонент ячейки таблицы типа c текстом и кружочком сбоку
 * @param
 */
export const RemarkTypeCell = (remark: any): JSX.Element => {
  const REMARK_TYPE = {
    revision: "Доработка",
    refusal: "Отказ",
  };

  const backgroundColor =
    REMARK_TYPE_COLORS[remark]?.background ??
    theme.palette.blackAndWhite.white;
  const textColor =
    REMARK_TYPE_COLORS[remark.status]?.text ??
    theme.palette.blackAndWhite.white;

  return (
    <Box sx={cellRemarkStyle(backgroundColor, textColor)}>
      <Typography fontSize={14}>{REMARK_TYPE[remark] ?? "-"}</Typography>
    </Box>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCellsRemarks = (_: string, cell: any, itemsInPage: any[]): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: 'true' },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else if (query?.last) {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    modalStore.open(VARIANT_MODAL.REMARK, {
      theme: THEME_MODAL.W_555,
      initialValues: cell,
      remarkId: cell.id,
    });
    setAnchorEl(null);
  };

  const mutateList =
    swrStore.mutators[SWR_KEYS.getRemarkList()];

  const deleteWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDelete = (resolve, reject) => {
    api.regulatoryInfo.deleteRemark(cell.id).then(() => {
      deleteWithCheckingLast();
      resolve()
    }).catch(reject);
    setAnchorEl(null);
  };



  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
  };

  const isEditRemark = userStore.isPermission(
    PERMISSIONS.registrationRemark,
    PERMISSION_CRUD.change
  )
  const isDeleteRemark = userStore.isPermission(
    PERMISSIONS.registrationRemark,
    PERMISSION_CRUD.delete
  )

  if (!isEditRemark && !isDeleteRemark) {
    return <></>
  }

  return (
    <Box>
      <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      {isEditRemark && (
          <>
            <StyledMenuItem onClick={handleClickEdit}>
              <EditIcon />
              {EDITING_CELL.editing}
            </StyledMenuItem>
          </>
        )}
        {isEditRemark && isDeleteRemark && (
          <Divider />
        )}
        {isDeleteRemark && (
          <StyledMenuItem onClick={handleClickDelete}>
            <DeleteIcon color={"error"} />
            <Typography color="error">
              {EDITING_CELL.delete}
            </Typography>
          </StyledMenuItem>
        )}
      </Menu>
    </Box>
  );
};
