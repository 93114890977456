import React, {
  Fragment,
  ReactElement,
  useMemo,
  useState,
} from "react";
import useSWR from "swr";
import qs from "qs";
import { useNavigate } from "react-router";
import { useSearchParams, } from "react-router-dom";
import BaseTable from "@/apps/Table";

import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";

import {
  ICultivarFieldVariety,
} from "@/api/interfaces/responses";

import {
  useQuery,
  useStores,
  useRightSidebar
} from "@/hooks";
import { columns } from "../models";
import { downloadFile, getToastErrorMessage, isUndefined } from "@/utils/helpers";
import {
  TCultivarFieldVarietyLabKeys,
  ICultivarFieldVarietyLabQueryParams
} from "../interfaces";
import {
  ADD_TEXT,
  EMPTY_LIST_TEXT,
  EMPTY_LIST_TEXT_WITH_FILTERS,
} from "../const";
import {
  SWR_KEYS,
  KEYS_SIDEBAR_VARIANT_MODULES,
  TRIAL_TYPE,
} from "@/const";
import { Box, Button, Menu, MenuItem, Stack } from "@mui/material";
import Filters from "@/components/Filters";
import useFilters
  from "@/pages/CultivarFieldVarietyLab/hooks/useFilters";
import AddIcon from "@mui/icons-material/Add";
import { ROUTES } from "@/apps/AppRouter/const";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import { reqInterface } from "@/api/interfaces";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { FormikHelpers, FormikValues } from "formik";
import { TOAST_TYPES } from "@/apps/Toast";

/**
 * Компонент, представляющий таблицу Лабораторные опыты
 * @returns {ReactElement}
 */
const Table = (): ReactElement => {
  const {
    api,
    swrStore,
    queryStringSidebarCollector,
    userStore,
    modalStore,
    toastStore,
  } = useStores();
  const [, setSearchParams] = useSearchParams();
  const query = useQuery<ICultivarFieldVarietyLabQueryParams>();
  const filters = useFilters();
  const { handleOpen } = useRightSidebar()
  const navigate = useNavigate()

  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orgeringArray);
  const {
    data,
    error,
    isLoading,
    mutate,
  } = useSWR(
    {
      _key: SWR_KEYS.getCultivarFieldVarietyLabList(),
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: query?.search,
      plan__cultivar: query?.cultivar?.key,
      plan__branch: query?.branch?.key,
      status: query?.status?.key,
      trial__year_of_testing: query?.year,
      laboratory: query?.laboratory?.key,
      is_lab: true,
      is_expert_assessment: false,
    },
    api.agriculturalLabour.getCultivarFieldVarietyList,
  );

  swrStore.addMutator(SWR_KEYS.getCultivarFieldVarietyLabList(), mutate);

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value: TCultivarFieldVarietyLabKeys[]) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  const isFilters: boolean = Object.keys(query).length > 0;

  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   *
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */
  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {
    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY_LAB,
      module_id: id,
    });

    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY_LAB,
      modalProps: {
        id,
        keySidebar: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY_LAB,
      },
    });
    setSearchParams(str);
  };
  const handleAdd = () => {
    navigate(`${ROUTES.addLab}`);
  };

  const canAddLab = userStore.isPermission(
    PERMISSIONS.agricultural_labourLabCultivarFieldVariety,
    PERMISSION_CRUD.add
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showToast = (error: string) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: error,
        severity: "error"
      }
    });
  };
  
  const files = [
    {
      title: "Результаты химико-технологических анализов за год",
      fetcher: api.agriculturalLabour.downloadLabCultivarChemicalAnalyses,
      type: TRIAL_TYPE.HP,
      isSowing: true,
      isChemicalAnalyses: true,
    },
  ];

  const handleDownload = (
    fetcher: (params: reqInterface.DownloadCultivarFieldSowingReportReq) => Promise<Blob>, name: string) => 
      (values, helpers: FormikHelpers<FormikValues>) => {
    helpers.setSubmitting(true);
    const payload = {
      cultivar: values.cultivar?.id as number,
      branch: values.branch?.id as number,
      plot: values.plot?.id as number,
      year: values?.year as number,
      laboratory: values?.laboratory?.id as number,
      variety: values?.variety?.id as number,
    };
    fetcher(payload).then(data => {
      downloadFile(data, name);
    }).catch(async (error) => {
      const message = await getToastErrorMessage(error);
      showToast(message);
    }).finally(() => {
      helpers.setSubmitting(false);
      modalStore.close();
    });
  };

  const handleClickFile = (
    fetcher: (params: reqInterface.DownloadCultivarFieldSowingReportReq) => Promise<Blob>, 
    name: string, 
    isSowing: boolean, 
    type: string,
    isChemicalAnalyses = false,
  ) => {
    if (isChemicalAnalyses) {
      modalStore.open(VARIANT_MODAL.DOWNLOAD_LOAD_CHEMICAL_ANALYSES, {
        theme: THEME_MODAL.W_555,
        title: name,
        handleSubmit: (values, helpers: FormikHelpers<FormikValues>) => {
          handleDownload(fetcher, name)(values, helpers);
        }
      });
      handleClose()
      return
    }
    modalStore.open(VARIANT_MODAL.DOWNLOAD_CULTIVAR_FIELD, {
      theme: THEME_MODAL.W_555,
      title: name,
      isSowing,
      type,
      handleSubmit: (values, helpers: FormikHelpers<FormikValues>) => {
        handleDownload(fetcher, name)(values, helpers);
      }
    });
    handleClose()
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
        />
        {canAddLab &&
          <Stack
            direction={"row"}
            gap={2}
          >
            <Button
              color="blue"
              onClick={handleClick}
              variant="outlined"
              startIcon={<FileDownloadOutlinedIcon />}
            >
              Документы
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
            >
              {
                files.map((file, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleClickFile(
                      file.fetcher, 
                      file.title, 
                      file.isSowing, 
                      file.type, 
                      file?.isChemicalAnalyses)}>
                    {file.title}
                  </MenuItem>
                ))
              }
            </Menu>
            <Button
              sx={{
                whiteSpace: "nowrap",
                flexShrink: "0",
              }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAdd}
            >
              Добавить
            </Button>
          </Stack>
        }
      </Box>

      <BaseTable<ICultivarFieldVariety, TCultivarFieldVarietyLabKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={ADD_TEXT}
        textEmptyTableWithFilter={
          EMPTY_LIST_TEXT_WITH_FILTERS
        }
        textEmptyTable={EMPTY_LIST_TEXT}
        onRowClick={handleOpenRightSidebar}
        overflowX="scroll"
      />
    </Fragment>
  );
};

export default Table;
