import React, {
  Fragment,
  ReactElement,
  useMemo,
  useState,
} from "react";
import useSWR, { BareFetcher, SWRResponse } from "swr";
import qs from "qs";
import { Box, Button } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

import {
  IBranchItem,
  IPaginatedResult,
} from "@/api/interfaces/responses";
import { IErrorResponse, IValue } from "@/interfaces";
import {
  useInfiniteScroll,
  useQuery,
  useStores,
} from "@/hooks";
import useRightSidebar from "@/hooks/useRightSidebar";
import Table from "@/apps/Table";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import {
  KEYS_SIDEBAR_VARIANT_MODULES,
  SWR_KEYS,
} from "@/const";
import Filters from "@/components/Filters";
import { IOffBudget } from "@/api/interfaces/requests";

import { isUndefined } from "@/utils/helpers";

import {
  ADD_OFFBUDGET_FACTS_TEXT,
  BRANCH_KEY,
  EMPTY_LIST_OFFBUDGET_FACTS_TEXT,
  EMPTY_LIST_OFFBUDGET_FACTS_TEXT_WITH_FILTERS,
} from "@/pages/OffBudget/const";
import { columns } from "../models";
import {
  IOffBudgetQueryParams,
  TOffBudgetKeys,
} from "../interfaces";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { useSearchParams } from "react-router-dom";
import useDebounce from "@/hooks/useDebounce";
import { getFilters } from "../utils";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 *
 * Компонент, представляющий таблицу планирования Внебюджет-план.
 * @returns {ReactElement}
 */

const TableFacts = (): ReactElement => {
  const {
    api,
    queryStringSidebarCollector,
    swrStore,
    modalStore,
    userStore
  } = useStores();
  const { handleOpen } = useRightSidebar();
  const query = useQuery<IOffBudgetQueryParams>();

  const [, setSearchParams] = useSearchParams();

  // переменная принимающая параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orderingArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orderingArray);

  const KEY_SWR = SWR_KEYS.getOffBudget();
  const {
    data,
    error,
    isLoading,
    mutate,
  }: SWRResponse<
    IPaginatedResult<IOffBudget>,
    IErrorResponse
  > = useSWR(
    {
      type: query.type ?? "plan",
      page: query?.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: query.search ?? undefined,
      branch: query.branch?.key ?? undefined,
      status: query.status?.key ?? undefined,
      _key: KEY_SWR,
    },
    api.offBudget.getOffBudgetList as BareFetcher<
      IPaginatedResult<IOffBudget>
    >,
  );

  const [searchBranch, setSearchBranch] = useState("");
  const dbouncedSearchBranchValue = useDebounce(
    searchBranch,
    500,
  );

  const handleChangePage = (value: number) => {

    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value: TOffBudgetKeys[]) => {
    const params = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(params);
    setOrderBy(value);
  };

  const handleOpenAddFact = () => {
    modalStore.open(VARIANT_MODAL.ADD_FACT, {
      onSubmit: onSuccessAddFact,
    });
  };

  const onSuccessAddFact = () => {
    modalStore.close();
  };

  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   *
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */

  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {
    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent:
        KEYS_SIDEBAR_VARIANT_MODULES.OFFBUDGET_SIDEBAR_FACT,
      module_id: id,
    });

    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.OFFBUDGET_SIDEBAR_FACT,
      modalProps: {
        id,
      },
    });
    setSearchParams(str);
  };

  const getKey = (index: number) => {
    return {
      key: BRANCH_KEY,
      page: index + 1,
      search: dbouncedSearchBranchValue,
    };
  };

  const fetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getPlanBranchesList({
      has_com_plan_facts: true,
      page,
      search,
    });
  };

  const { data: branches, handleScroll } =
    useInfiniteScroll<IBranchItem>(getKey, fetcher, {
      revalidateFirstPage: false,
    });

  const branch: IValue[] = branches.map(({ name, id }) => {
    return { label: name, key: id };
  });

  const filters = getFilters(
    handleScroll,
    branch,
    query.branch,
    query.status,
    setSearchBranch,
  );

  swrStore.addMutator(KEY_SWR, mutate);

  // переменная которая показывает применены ли фильтры
  const isFilters: boolean = Object.keys(query).length > 0;

  const canAddFact = userStore.isPermission(
    PERMISSIONS.extrabudgetaryComPlanFact,
    PERMISSION_CRUD.add
  )

  return (
    <Fragment>
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
        />
        {canAddFact && 
        <Button
          sx={{
            whiteSpace: "nowrap",
            flexShrink: "0",
          }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAddFact}
        >
          Добавить факт
        </Button>
        }
      </Box>

      <Table<IOffBudget, TOffBudgetKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query?.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={ADD_OFFBUDGET_FACTS_TEXT}
        textEmptyTableWithFilter={
          EMPTY_LIST_OFFBUDGET_FACTS_TEXT_WITH_FILTERS
        }
        textEmptyTable={EMPTY_LIST_OFFBUDGET_FACTS_TEXT}
        onRowClick={handleOpenRightSidebar}
        overflowX="scroll"
      />
    </Fragment>
  );
};

export default TableFacts;
