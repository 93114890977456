import yup from "@/utils/yup";
import { Dayjs } from "dayjs";
import { reqInterface } from "@/api/interfaces";
import {
  MIN_NUMBERS,
  MAX_NUMBERS,
  TRIAL_TYPE
} from "@/const";

export const FIELDS_NAME = {
  REGION: "region",
  BRANCH: "branch",
  PLOT: "plot",
  CULTIVAR: "cultivar",
  FIELD: "field",
  AREA: "area",
  UNIT: "unit",
  LOCATION: "location",
  PREDECESSOR: "predecessor",
  YEAR: "year",
  TYPE_TRIAL: "type_trial",
  SEEDING_RATE: "seeding_rate",
  REPETITIONS_NUMBER: "repetitions_number",
  LAYERS_NUMBER: "layers_number",
  MAIN_PRODUCTION: "main_production",
  BY_PRODUCTS_PRODUCTION: "by_products_production",
  TECH_MAP_PLAN: "tech_map_plan",
  SOWING_LENGTH: "sowing_length",
  SOWING_WIDTH: "sowing_width",
  ACCOUNTING_LENGTH: "accounting_length",
  ACCOUNTING_WIDTH: "accounting_width",
  PLAN: "plan"
} as const;

export const initialValues = {
  [FIELDS_NAME.REGION]: null,
  [FIELDS_NAME.BRANCH]: null,
  [FIELDS_NAME.PLOT]: null,
  [FIELDS_NAME.CULTIVAR]: null,
  [FIELDS_NAME.FIELD]: null,
  [FIELDS_NAME.TECH_MAP_PLAN]: null,
  [FIELDS_NAME.PREDECESSOR]: null,
  [FIELDS_NAME.LOCATION]: null,
  [FIELDS_NAME.UNIT]: null,
  [FIELDS_NAME.PLAN]: null,
  [FIELDS_NAME.TYPE_TRIAL]: "",
  [FIELDS_NAME.AREA]: "",
  [FIELDS_NAME.SEEDING_RATE]: "",
  [FIELDS_NAME.REPETITIONS_NUMBER]: "",
  [FIELDS_NAME.LAYERS_NUMBER]: "",
  [FIELDS_NAME.MAIN_PRODUCTION]: "",
  [FIELDS_NAME.BY_PRODUCTS_PRODUCTION]: "",
  [FIELDS_NAME.SOWING_LENGTH]: "",
  [FIELDS_NAME.SOWING_WIDTH]: "",
  [FIELDS_NAME.ACCOUNTING_WIDTH]: "",
  [FIELDS_NAME.ACCOUNTING_LENGTH]: "",
  [FIELDS_NAME.YEAR]: null,
};

export interface IValue {
  id: number,
  label: string
}

export interface IFormik {
  [FIELDS_NAME.REGION]: IValue | null,
  [FIELDS_NAME.BRANCH]: IValue | null,
  [FIELDS_NAME.PLOT]: IValue | null,
  [FIELDS_NAME.CULTIVAR]: IValue | null,
  [FIELDS_NAME.FIELD]: IValue | null,
  [FIELDS_NAME.TECH_MAP_PLAN]: IValue | null,
  [FIELDS_NAME.PREDECESSOR]: IValue | null,
  [FIELDS_NAME.LOCATION]: IValue | null,
  [FIELDS_NAME.UNIT]: IValue | null,
  [FIELDS_NAME.PLAN]: IValue | null,
  [FIELDS_NAME.TYPE_TRIAL]: string,
  [FIELDS_NAME.SEEDING_RATE]: string,
  [FIELDS_NAME.REPETITIONS_NUMBER]: string | number,
  [FIELDS_NAME.LAYERS_NUMBER]: string | number,
  [FIELDS_NAME.MAIN_PRODUCTION]: string,
  [FIELDS_NAME.BY_PRODUCTS_PRODUCTION]: string,
  [FIELDS_NAME.SOWING_LENGTH]: string,
  [FIELDS_NAME.SOWING_WIDTH]: string,
  [FIELDS_NAME.ACCOUNTING_WIDTH]: string,
  [FIELDS_NAME.ACCOUNTING_LENGTH]: string,
  [FIELDS_NAME.AREA]: string,
  [FIELDS_NAME.YEAR]: Dayjs | null | any,
}

export const serializeValues = (values): IFormik => ({
  cultivar: values?.cultivar ? {
    id: values.cultivar.id,
    label: values.cultivar.name,
  } : null,
  field: values?.field ? {
    id: values.field.id,
    label: values.field.name,
  } : null,
  location: values?.field ? {
    id: values.field.location.id,
    label: values.field.location.name,
  } : null,
  plot: values?.field ? {
    id: values.field.location.plot.id,
    label: values.field.location.plot.name,
  } : null,
  branch: values?.field ? {
    id: values.field.location.plot.branch.id,
    label: values.field.location.plot.branch.name,
  } : null,
  region: values?.field ? {
    id: values.field.location.plot.branch.region.id,
    label: values.field.location.plot.branch.region.name,
  } : null,
  plan: values?.plan ? {
    id: values.plan.id,
    label: `План ${values.plan.number}`,
  } : null,
  tech_map_plan: values?.tech_map_plan ? {
    id: values.tech_map_plan.id,
    label: values.tech_map_plan.name,
  } : null,
  predecessor: values?.predecessor ? {
    id: values.predecessor.id,
    label: values.predecessor.name,
  } : null,
  unit: values?.unit ? {
    id: values.unit.id,
    label: values.unit.name,
  } : null,
  area: values?.area || "",
  type_trial: values?.type_trial || "",
  seeding_rate: values?.seeding_rate || "",
  repetitions_number: values?.repetitions_number || "",
  layers_number: values?.layers_number || "",
  main_production: values?.main_production || "",
  by_products_production: values?.by_products_production || "",
  sowing_length: values?.sowing_length || "",
  sowing_width: values?.sowing_width || "",
  accounting_width: values?.accounting_width || "",
  accounting_length: values?.accounting_length || "",
  year: null,
});

export const serializeFormToValues = (values: IFormik): reqInterface.ICultivarFieldReq => ({
  cultivar: values.cultivar?.id,
  field: values.field?.id,
  tech_map_plan: values.tech_map_plan?.id as number,
  predecessor: values.predecessor?.id,
  unit: values.unit?.id,
  plan: values.plan?.id,
  type_trial: values?.type_trial || undefined,
  seeding_rate: values?.seeding_rate || undefined,
  repetitions_number: values?.repetitions_number as number || undefined,
  layers_number: values?.layers_number as number || undefined,
  main_production: values?.main_production || undefined,
  by_products_production: values?.by_products_production || undefined,
  sowing_length: values?.sowing_length || undefined,
  sowing_width: values?.sowing_width || undefined,
  accounting_width: values?.accounting_width || undefined,
  accounting_length: values?.accounting_length || undefined,
  area: values?.area || undefined,
});

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
  [FIELDS_NAME.YEAR]: yup.mixed().nullable(),
  [FIELDS_NAME.REGION]: yup.mixed().nullable(),
  [FIELDS_NAME.BRANCH]: yup.mixed().nullable(),
  [FIELDS_NAME.PLOT]: yup.mixed().nullable(),
  [FIELDS_NAME.LOCATION]: yup.mixed().nullable(),
  [FIELDS_NAME.FIELD]: yup.mixed().required(),
  [FIELDS_NAME.AREA]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX).required(),
  [FIELDS_NAME.TYPE_TRIAL]: yup.string().required(),
  [FIELDS_NAME.PREDECESSOR]: yup.mixed().nullable(),
  [FIELDS_NAME.SEEDING_RATE]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX),
  [FIELDS_NAME.UNIT]: yup.mixed().nullable(),
  [FIELDS_NAME.REPETITIONS_NUMBER]: yup.number().min(1).max(4).required(),
  [FIELDS_NAME.LAYERS_NUMBER]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX),
  [FIELDS_NAME.MAIN_PRODUCTION]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX),
  [FIELDS_NAME.BY_PRODUCTS_PRODUCTION]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX),
  [FIELDS_NAME.PLAN]: yup.mixed().required(),
  [FIELDS_NAME.SOWING_LENGTH]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX).required(),
  [FIELDS_NAME.SOWING_WIDTH]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX).required(),
  [FIELDS_NAME.ACCOUNTING_LENGTH]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX).required(),
  [FIELDS_NAME.ACCOUNTING_WIDTH]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX).required(),
  [FIELDS_NAME.TECH_MAP_PLAN]: yup.mixed().required(),
});

export const trialTypeOptions = [
  {
    key: TRIAL_TYPE.HP,
    label: "Хоз. полезность",
  },
  {
    key: TRIAL_TYPE.OOS,
    label: "ООС",
  },
  {
    key: TRIAL_TYPE.PHYTO,
    label: "Фитопатология",
  },
];