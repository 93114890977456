import React, { Fragment, useEffect } from "react";
import {
  Form,
  useFormikContext,
} from "formik";
import { useStores } from "@/hooks";

import {
  Box,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import {
  IProps,
  PARAMETER_FIELD_NAME,
  TForm,
} from "../const";
import { RateAutocomplete, VarietyTrialAutocomplete } from "@/components/Autocompletes";
import FastRegexTextField from "@/components/FastRegexTextField";
import YearDatePicker from "@/components/Fields/YearDatePicker";

/**
 * Форма создания/редактирования
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const RateForm: React.FC<IProps> = ({
  modalProps,
}) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<TForm>();

  const title = modalProps.parameterId
    ? "Редактировать запись"
    : "Добавить запись";

  useEffect(() => {
    formik.setFieldError('non_field_errors', undefined)
  }, [formik.values])

  return (
    <Fragment>
      <Box p={3}>
        <Typography>Показатель качества</Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <YearDatePicker
            name={PARAMETER_FIELD_NAME.YEAR_OF_TESTING}
            label="Год испытания"
            fullWidth
            required
          />
          <VarietyTrialAutocomplete
            name={PARAMETER_FIELD_NAME.VARIETY_TRIAL_PLOT}
            label="ГСУ"
            q={{
              year_of_testing: formik.values[PARAMETER_FIELD_NAME.YEAR_OF_TESTING],
              variety: modalProps?.varietyId
            }}
            required
          />
          <RateAutocomplete
            name={PARAMETER_FIELD_NAME.RATE}
            label="Показатель качества"
            required
            getLabel={el => `${el?.name} ${el?.unit?.name}`}
          />
          <Stack direction={"row"} justifyContent={'space-between'} gap={2}>
            <Box
              width={"100%"}
            >
              <FastRegexTextField
                name={PARAMETER_FIELD_NAME.VALUE}
                label="Значение"
                size="small"
                fullWidth
                required={false}
              />
            </Box>
          </Stack>
        </Stack>
        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={formik.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default RateForm;
