import React, { useEffect } from "react";
import qs from "qs";
import { AxiosError } from "axios";
import {
  useSearchParams,
  useNavigate
} from "react-router-dom";
import {
  Box,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  IPlanning,
  IAgriculturalLabourCultivarField
} from "@/api/interfaces/responses";
import {
  CULTIVAR_FIELD_TYPES_COLOR,
  CULTIVAR_FIELD_TYPES_ICON,
  EDITING_CELL,
  CULTIVAR_FIELD_TRIALS_TYPES,
} from "../const";
import {
  StyledMenuItem
} from "@/components/StyledMenuItem";
import { buttonDotsStyle } from "@/apps/Table/style";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DeleteIcon, EditIcon } from "@/components/icons";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import {
  CELL_TYPES
} from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import {
  useQuery,
  useStores,
  useRightSidebar
} from "@/hooks";
import { SWR_KEYS } from "@/const";
import { ROUTES } from "@/apps/AppRouter/const";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";
import { StyledMenu } from "@/components/StyledMenu";

/**
 * @component PlantingYearCells
 * * компонент ячейки таблицы Год посева
 * @param
 */

export const PlantingYearCells = (
  _: string,
  cell: IAgriculturalLabourCultivarField,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">
        {cell.field?.planting_year || "-"}
      </Typography>
    </Box>
  );
};

/**
 * @component PlotCells
 * * компонент ячейки таблицы ГСУ
 * @param
 */

export const PlotCells = (
  _: string,
  cell: IAgriculturalLabourCultivarField,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">
        {cell.field?.location?.plot?.name || "-"}
      </Typography>
    </Box>
  );
};

/**
 * @component TrialTypeCells
 * * компонент ячейки таблицы Типа Испытаний
 * @param
 */

export const TrialTypeCells = (
  _: string,
  cell: IAgriculturalLabourCultivarField,
): JSX.Element => {
  const trialType = cell.type_trial;
  const trialTypeText = trialType
    ? CULTIVAR_FIELD_TRIALS_TYPES[trialType]
    : "";
  const trialTypeIcon = trialType
    ? CULTIVAR_FIELD_TYPES_ICON[trialType]
    : "";
  const trialTypeColors = trialType
    ? CULTIVAR_FIELD_TYPES_COLOR[trialType]
    : "";

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
    >
      <img
        src={trialTypeIcon}
        height={15}
        width={15}
        alt="иконка"
      />
      <Typography
        variant="body2"
        sx={{ color: trialTypeColors }}
      >
        {trialTypeText}
      </Typography>
    </Stack>
  );
};

/**
 * @component CultivarCells
 * * компонент ячейки таблицы наименование культуры
 * @param
 */

export const CultivarCells = (
  _: string,
  cell: IAgriculturalLabourCultivarField,
): JSX.Element => {
  // debugger
  return (
    <Box>
      <Typography variant="body2">
        {cell.cultivar?.name}
      </Typography>
    </Box>
  );
};

/**
 * @component FieldNameCells
 * * компонент ячейки таблицы наименование поля
 * @param
 */

export const FieldCells = (
  _: string,
  cell: IAgriculturalLabourCultivarField,
): JSX.Element => {
  return (
    <Box display="flex" alignItems="center"
         flexWrap="nowrap">
      <Box sx={{
        width: 18,
        height: 18,
        borderRadius: 1,
        background: cell.field?.colour
      }} mr={1}/>
      <Typography variant="body2">
        {cell.field?.name}
      </Typography>
    </Box>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCells = (
  _: string,
  storage: IPlanning,
  itemsInPage: any[],
): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const query = useQuery<any>();
  const {
    sidebarProps,
    handleCloseInstantly
  } = useRightSidebar();
  const navigate = useNavigate();
  const canChangeCultivarField = userStore.isPermission(PERMISSIONS.agriculturalLabourCultivarField, PERMISSION_CRUD.change);
  const canDeleteCultivarField = userStore.isPermission(PERMISSIONS.agriculturalLabourCultivarField, PERMISSION_CRUD.delete);
  const [, setSearchParams] = useSearchParams();

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else if (query?.last) {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  const mutateTable =
    swrStore.mutators[SWR_KEYS.getAgriculturalLabourCultivarFieldList()];

  const mutateWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateTable && mutateTable();
    }
  };

  const handleDeletePlanning = (
    resolve: () => void,
    reject: (error: AxiosError) => void,
  ) => {
    api.agriculturalLabour
      .deleteCultivarFieldById(storage.id)
      .then(() => {
        if (storage.id == sidebarProps.id) {
          setSearchParams(undefined);
          handleCloseInstantly();
        }
        mutateWithCheckingLast();
        resolve();
      })
      .catch(reject)
      .finally(() => setAnchorEl(null));
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.universal,
      id: storage.id,
      entityName: "план",
      handleDelete: handleDeletePlanning,
    };

    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigate(`${storage.id}/${ROUTES.addCultivarField}`);
  };

  if(!canChangeCultivarField && !canDeleteCultivarField){
    return <></>
  }

  return (
    <Box>
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon/>
      </IconButton>

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          canChangeCultivarField && (
            <StyledMenuItem onClick={handleClickEdit}>
              <EditIcon/>
              Редактировать
            </StyledMenuItem>
          )
        }
        {
          canDeleteCultivarField && (
            <StyledMenuItem onClick={handleClickDelete}>
              <DeleteIcon color="error"/>
              <Typography color="error">
                {" "}
                {EDITING_CELL.delete}
              </Typography>
            </StyledMenuItem>
          )
        }
      </StyledMenu>
    </Box>
  );
};
