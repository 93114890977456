import React, { useEffect, useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { Stack } from "@mui/system";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import Autocomplete from "@/components/Autocomplete";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME, IForm } from "../const";
import { TChemistryType } from "@/api/types";
import { StyledChip } from "../../CreateVariety/styles";
import AddChip from "@/components/Chips/AddChip";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { IPlot } from "@/api/interfaces/responses";

interface IProps {
  handleClose: () => void;
  modalProps: {
    planId: number;
    type: TChemistryType;
    nameLabel: string;
    countLabel: string;
    plots?: IPlot[];
    _key?: number;
    initialValue: IForm;
  };
}

const AddLaboratoryForm: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const { api, swrStore, modalStore } = useStores();
  const [search, setSearch] = useState("");
  const formik = useFormikContext<IForm>();

  const swrKey = SWR_KEYS.getLaboratoriesList();
  const getKeyOrganization = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    search: search,
  });

  const { data, handleScroll, mutate, isEmpty } =
    useInfiniteScroll(
      getKeyOrganization,
      api.regulatoryInfo.getLaboratoriesList,
      {
        revalidateFirstPage: false,
      },
    );

  const handleChange = (event, child) => {
    formik.setFieldValue(FIELDS_NAME.LABORATORY, child);
  };

  useEffect(() => {
    swrStore.addMutator(swrKey, mutate);
  }, []);

  const handleDeletePlots = (id: number) => {
    const newChip =
      formik.values.plots &&
      formik.values.plots.filter(item => item.id !== id);
    formik.setFieldValue(FIELDS_NAME.PLOTS, newChip);
  }

  const handleAddPlots = () => {
    modalStore.open(VARIANT_MODAL.ADD_PLAN_PLOTS, {
      onSubmit: (values: IPlot[]) => {
        modalStore.setModalPropsByKey(modalProps._key, {
          initialValue: { ...formik.values, plots: values }
        }
        )
        modalStore.pop()
      },
      initialValue: formik.values.plots,
      isForLaboratoryForm: true,
    });
  }

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <Field name={FIELDS_NAME.LABORATORY}>
          {({ field, meta }) => (
            <FormControl required>
              <Autocomplete
                {...field}
                onChange={handleChange}
                label={"Лаборатория"}
                required={true}
                data={data}
                handleScroll={handleScroll}
                search={setSearch}
                error={meta.touched && !!meta.error}
              />

              {isEmpty && (
                <Alert sx={{ mt: 2 }} severity="warning">
                  Справочник лаборатории не заполнен
                </Alert>
              )}

              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field
          name={FIELDS_NAME.PLOTS}
        >
          {({ field, meta }) => {
            return (
              <FormControl>
                <FormLabel>
                  ГСУ
                </FormLabel>
                <Stack
                  direction={"row"}
                  flexWrap={"wrap"}
                  alignItems={"center"}
                  gap={1}
                >
                  {field.value
                    ? field?.value?.map(
                      (item) => (
                        <StyledChip
                          key={item?.id}
                          size="small"
                          variant="outlined"
                          label={item?.name}
                          onDelete={() => handleDeletePlots(item?.id)}
                        />
                      ),
                    )
                    : null}
                  <AddChip
                    onClick={handleAddPlots}
                  />
                </Stack>
                <FormHelperText
                  error={!!meta.error}
                >
                  {meta.error}
                </FormHelperText>
              </FormControl>
            );
          }}
        </Field>
      </Stack>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default AddLaboratoryForm;
