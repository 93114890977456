import React, { useMemo } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { Box, Button, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ROUTES } from "@/apps/AppRouter/const";
import {
  TPlanningTrialType,
  TStatementType
} from "@/api/types";

import {
  HpIcon,
  OSIcon,
  PhytoIcon
} from "@/components/icons";
import {
  StyledButton
} from "@/apps/Modals/ModalContent/SelectTrial/styles";
import { TRIAL_TYPE, STATEMENT_TYPE } from "@/const";
import {
  TRIAL_TYPES_SUBMIT,
  validationSchema
} from "@/apps/Modals/ModalContent/SelectTrial/const";
interface IFormik {
  trial_type: TPlanningTrialType;
}

interface ISelectTrial {
  handleClose: () => void;
  modalProps: {
    onSubmitType: keyof typeof TRIAL_TYPES_SUBMIT;
    type: TStatementType
    id: number | string;
    title: string;
    cultivarId?: number | string;
    cultivarName?: string;
  };
}


export const items = [
  {
    type: TRIAL_TYPE.HP,
    label: "Испытания на хозяйственную полезность",
    icon: <HpIcon/>,
    disabled: false
  },
  {
    type: TRIAL_TYPE.PHYTO,
    label: "Фитопатология",
    icon: <PhytoIcon/>,
    disabled: false
  },
  {
    type: TRIAL_TYPE.OOS,
    label: "Испытания на отличимость, однородность и стабильность",
    icon: <OSIcon/>,
    disabled: false
  },
];

/** Модалка выбора типа испытания */
const SelectTrial: React.FC<ISelectTrial> = ({
  handleClose,
  modalProps
}) => {
  const {
    onSubmitType = "create",
    id,
    title = "Добавить испытание"
  } = modalProps || {};
  const navigate = useNavigate();
  
  const getPath = {
    [TRIAL_TYPE.HP]: (id: string) => `${id}/${ROUTES.hpTrial}`,
    [TRIAL_TYPE.OOS]: (id: string) => `${id}/${ROUTES.oosTrial}`,
    [TRIAL_TYPE.PHYTO]: (id: string) => `${id}/${ROUTES.phytoTrial}?cultivar=${modalProps.cultivarId}&cultivarName=${modalProps.cultivarName}`,
  };

  const create = (values) => {
    if (!id) return console.error("[ERR] - не переданн ид для создания!!!");
    navigate(getPath[values.trial_type](id));
    handleClose();
  };

  const copy = () => {
    if (!id) return console.error("[ERR] - не переданн ид для копированния!!!");
  };

  const ON_SUBMIT_VARIANTS = {
    create,
    copy
  };

  const formik = useFormik<IFormik>({
    initialValues: { trial_type: "" as TPlanningTrialType },
    validationSchema,
    validateOnMount: true,
    onSubmit: ON_SUBMIT_VARIANTS[onSubmitType]
  });

  const handleChange = (type: TPlanningTrialType) => {
    formik.setFieldValue("trial_type", type);
  };

  const handleCancel = () => {
    handleClose();
  };

  const trials = useMemo(() => {
    return modalProps.type === STATEMENT_TYPE.PATENT_STATEMENT
      ? items.filter(item => item.type !== TRIAL_TYPE.HP)
      : items;
  }, [modalProps.type]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Box p={3}>
        <Typography
          variant="h2"
        >
          {title}
        </Typography>
      </Box>
      <Divider/>
      <Box p={3}>
        <Typography marginBottom="20px" fontSize={14}>
          Выберите вид испытания:
        </Typography>
        <Grid container spacing={1}>
          {
            trials.map((el) => (
              <Grid key={el.type} item xs={6}>
                <StyledButton
                  startIcon={el.icon}
                  fullWidth
                  variant="outlined"
                  trialType={el.type}
                  active={el.type === formik.values.trial_type}
                  onClick={() => handleChange(el.type)}
                  disabled={el.disabled}
                >
                  {el.label}
                </StyledButton>
              </Grid>))
          }
        </Grid>
      </Box>
      <Divider/>
      <Box p={3} display="flex"
           justifyContent="space-between">
        <Button variant="text" color="red"
                onClick={handleCancel}>
          Отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!formik.values.trial_type || formik.isSubmitting}
        >
          Продолжить
        </Button>
      </Box>
    </form>
  );
};

export default SelectTrial;
