import TextField from "@/components/TextField";
import { SearchIcon } from "@/components/icons";
import { InputAdornment, SxProps } from "@mui/material";
import React from "react";

interface SearchFilterProps {
  onSearch: (value?: string) => void;
  defaultSearch?: string;
  searchStyle?: SxProps;
}

/**
 * инпут для поля search
 * @param filter - информация о фильтре (имя, тип и тд.), в том числе коллбек на запрос следующей страницы
 * @param onChange - коллбек работающий в момент изменения данных
 * @returns
 */
const SearchFilter: React.FC<SearchFilterProps> = ({
  onSearch,
  defaultSearch,
  searchStyle,
}) => {
  const handleChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const hasSearch =
      event?.target.value !== "" ? event?.target.value : undefined;
    onSearch(hasSearch);
  };

  return (
    <TextField
      sx={{ minWidth: "150px", width: "300px", flexGrow: 1, ...searchStyle }}
      size={"small"}
      label="Поиск"
      defaultValue={defaultSearch}
      onChange={handleChange}
      variant="outlined"
      fullWidth={true}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchFilter;
