import AddEntities from "../AddEntities/AddEntities";
import { IPlot } from "@/api/interfaces/responses";
import React, { useState } from "react";
import { useStores } from "@/hooks";
import { PAGE_SIZE, SWR_KEYS } from "@/const";
import { VARIANT_MODAL } from "../../const";
import useToastContent, {
  TOAST_CONTENT_TYPE,
} from "@/hooks/useToastContent";
import { v4 as uuidv4 } from "uuid";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    initialValue?: IPlot[];
    branchID?: number;
    isForLaboratoryForm?: boolean;
    onSubmit?: (values: IPlot[]) => void;
  };
}

/**
 * Модалка добавления Участков ГСУ в деталке плана
 * @param handleClose
 * @param modalProps
 * @component
 */

const AddPlanPlots: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const {
    modalStore,
    api,
    userStore,
    queryStringSidebarCollector,
    toastStore,
    swrStore,
  } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const currentPlanId =
    queryStringSidebarCollector.getCurrentModuleId();
  const currentUserId = userStore.id;
  const toastContent = useToastContent(
    TOAST_CONTENT_TYPE.SERVER_ERROR,
  );

  const getLabel = (subject: IPlot) => subject.name;

  const mutatorGSUList =
    swrStore.mutators[
      SWR_KEYS.getPlanningPlotList(Number(currentPlanId))
    ];

  const mutateGSUModal = swrStore.mutators["getPlotList"];

  mutateGSUModal && mutateGSUModal();

  const handleAccept = (values: IPlot[]) => {
    if (modalProps.isForLaboratoryForm) {
      modalProps.onSubmit && modalProps.onSubmit(values)
      return
    }
    const payload = values.map((el) => ({
      editor: currentUserId,
      plan: currentPlanId,
      plot: el.id,
    }));
    setIsLoading(true);
    api.planning
      .postPlanningPlot(payload)
      .then(() => {
        handleClose();
        mutatorGSUList();
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddClose = () => {
    modalStore.open(VARIANT_MODAL.ORIGINATORS, modalProps);
  };

  const fetcher = (args) => {
    const { page, search , branch} = args;
    return api.regulatoryInfo.getPlotList({
      page,
      page_size: PAGE_SIZE,
      name: search,
      plan_id: !modalProps?.isForLaboratoryForm ? currentPlanId : undefined,
      branch, 
    });
  };

  const props = {
    ...modalProps,
    q: { branch: modalProps.branchID },      
    fetcher,
    key: uuidv4(),
    handleClose,
    title: "Добавить ГСУ",
    handleAccept,
    handleAddClose,
    disabled: isLoading,
    getLabel: getLabel,
    saveButtonLabel: "Добавить",
  };
  return (
    <AddEntities<IPlot>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default AddPlanPlots;
