import React, { useEffect } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Box, Divider, IconButton, Menu, Typography } from "@mui/material";
import { EditIcon, DeleteIcon } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { SWR_KEYS } from "@/const";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { IBranchItem } from "@/api/interfaces/responses";
import { getFullName } from "@/utils/helpers";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * @component RegionCell
 * * компонент ячейки таблицы для названия региона
 * @param
 */
export const RegionCell = (_: any, branch: IBranchItem): JSX.Element => {
  return (
    <Box>
      <Typography fontSize={14}>{branch?.region?.name ?? "-"}</Typography>
    </Box>
  );
};

/**
 * @component StaffCell
 * * компонент ячейки таблицы начальник филиала
 * @param
 */
export const StaffCell = (_: any, branch: IBranchItem): JSX.Element => {
  return (
    <Box>
      <Typography fontSize={14}>{branch?.staff_director ? getFullName(branch?.staff_director) : "-"}</Typography>
    </Box>
  );
};

/**
* @component PhoneCell
* * компонент ячейки таблицы для телефона
* @param
*/
export const PhoneCell = (_: any, branch: IBranchItem): JSX.Element => {
  const displayPhones = branch?.phones.slice(0, 2);
  const hasMore = branch?.phones.length > 2;
  return (
    <Box>
      {displayPhones.map((i) => (
        <Typography key={i.phone} fontSize={14}>
          {i?.phone ?? "-"}
        </Typography>
      ))}
      {hasMore && (
        <Typography fontSize={14}>...</Typography>
      )}
    </Box>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCellsBranch = (_: string, cell: any, itemsInPage: any[]): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);

  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: 'true' },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else if (query?.last) {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    modalStore.open(VARIANT_MODAL.BRANCH, {
      theme: THEME_MODAL.W_555,
      branchId: cell.id,
    });
    setIsOpen(null);
  };

  const mutateList =
    swrStore.mutators[SWR_KEYS.getBranchList()];

  const deleteWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDelete = (resolve, reject) => {
    api.regulatoryInfo.deleteBranchById(cell.id).then(() => {
      deleteWithCheckingLast();
      resolve()
    }).catch(reject);
    setIsOpen(null);
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
  };

  const canEditBranch= userStore.isPermission(
    PERMISSIONS.regulatory_infoBranch,
    PERMISSION_CRUD.change
  )
  const canDeleteBranch = userStore.isPermission(
    PERMISSIONS.regulatory_infoBranch,
    PERMISSION_CRUD.delete
  )

  if (!canEditBranch && !canDeleteBranch) {
    return <></>
  }

  return (
    <Box>
      <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={isOpen} open={open} onClose={handleClose}>
        {canEditBranch && 
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon />
          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
        {canEditBranch && canDeleteBranch && 
        <Divider />
        }
        {canDeleteBranch && 
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color={"error"} />
          <Typography color='error'>{EDITING_CELL.delete}</Typography>
        </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};
