import React from "react";
import { Formik, FormikHelpers } from "formik";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import AddWorkForm from "./components/AddWorkForm";
import {
  FIELDS_NAME,
  IFormik,
  validationSchema,
} from "./const";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { SWR_KEYS } from "@/const";

/**
 * Компонент модалки добавления технологической операции для внебюджета деталка культура
 * @param props
 * @param props.cultivarId - id плана внебюджета
 * @param props.title - название модалки
 * @param props.isEdit - режим редактирования
 * @param props.initialValues - значения по умолчанию
 * 
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    id: number;
    cultivarId: number;
    title?: string;
    initialValues?: IFormik;
    isEdit?: boolean;
    plotId?: number;
  };
}



const AddWork: React.FC<IProps> = (props) => {

  const initialValues = Object.assign(
    {
      [FIELDS_NAME.WORK]: null,
      [FIELDS_NAME.FUEL_PRICE]: null,
    },
    {
      fuel_price: props.modalProps.initialValues?.fuel_price,
    }
  );
  
  const title = props.modalProps.title
  const isEdit = Boolean(props.modalProps.isEdit);
  const { api, toastStore, swrStore } = useStores();

  const mutate =
    swrStore.mutators[
      SWR_KEYS.getOffBudgetComWork(props.modalProps.id)
    ];

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    const payload = {
      com_cultivar: props.modalProps.id,
      work: values.work.id,
      fuel_price: values.fuel_price,
    };

    {isEdit
      ? api.offBudget
          .patchOffBudgetWork({
            work: values.work.id,
            fuel_price: values.fuel_price,
          })
          .then(() => {
            mutate();
            props.handleClose();
          })
          .catch((error) => {
            toastStore.createToast({
              type: TOAST_TYPES.ALERT,
              toastProps: {
                message: errorToString(error),
                severity: "error",
              },
            });
          })
          .finally(() => {
            helpers.setSubmitting(false);
          })
      : api.offBudget
          .addOffBudgetWork(payload)
          .then(() => {
            mutate();
            props.handleClose();
          })
          .catch((error) => {
            toastStore.createToast({
              type: TOAST_TYPES.ALERT,
              toastProps: {
                message: errorToString(error),
                severity: "error",
              },
            });
          })
          .finally(() => {
            helpers.setSubmitting(false);
          });}
  };

  if (!props.modalProps.cultivarId) {
    console.log("Не передан id плана");
    return null;
  }

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {title ?? 'Добавить технологическую операцию'}
        </Typography>
      </Box>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => <AddWorkForm {...props} />}
      />
    </Box>
  );
};

export default AddWork;
