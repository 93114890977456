import React from "react";
import { Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Box, Button, Divider, } from "@mui/material";
import { FIELDS_NAME, IFormik } from "../const";
import { useStores } from "@/hooks";
import {
  BranchAutocomplete,
  CultivarAutocomplete,
  PlotAutoComplete,
} from "@/components/Autocompletes";
import { YearDatePicker } from "@/components/Fields";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import { TRIAL_TYPE } from "@/const";

const DownloadCultivarFieldForm = ({modalProps}) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<IFormik>();
  const {isSowing, type, isWithoutCulture} = modalProps

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        {!isWithoutCulture ?
          <CultivarAutocomplete
            name={FIELDS_NAME.CULTIVAR} required
          /> : null
        }
        {!isSowing && type === TRIAL_TYPE.HP ? 
        <PlotAutoComplete name={FIELDS_NAME.PLOT} required />
        :
        <BranchAutocomplete name={FIELDS_NAME.BRANCH} required />
         }
        <YearDatePicker name={FIELDS_NAME.YEAR}
          fullWidth={true} required label="Год" />
      </Stack>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red"
          onClick={() => modalStore.close()}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<FileDownloadOutlinedIcon />}
          disabled={formik.isSubmitting}
        >
          Скачать
        </Button>
      </Box>
    </Form>
  );
};

export default DownloadCultivarFieldForm;
