import React from "react";
import { FastField, Field, Form, useFormikContext } from "formik";
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import { IFormik } from "../const";
import { FIELD_NAME } from "../const";
import CheckIcon from "@mui/icons-material/Check";
import { PlotAutoComplete } from "@/components/Autocompletes";
import YearDatePicker from "@/components/Fields/YearDatePicker";

interface IProps {
  handleClose: () => void;
  modalProps: {
    indicatorType: string;
    varietyId: number;
    label?: string;
  };
}

const AddVarietyTrialForm: React.FC<IProps> = ({
  handleClose,
}) => {
  const formik = useFormikContext<IFormik>();

  const handleChangePlot = (child) => {
    formik.setFieldValue(FIELD_NAME.PLOT, child);
  };

  const handleChangeIsStandart = (event) => {
    formik.setFieldValue(FIELD_NAME.IS_STANDARD, event.target.checked);
  }
  
  return (

    <Form noValidate>
      <Stack
        gap={2}
        p={3}
      >
        <YearDatePicker
          name={FIELD_NAME.YEAR_OF_TESTING}
          label="Год испытания"
          fullWidth
          required
        />
        <FastField name={FIELD_NAME.TYPE_TRIAL}>
          {({ field }) => (
            <FormControl>
              <RadioGroup {...field}>
                <Stack
                  direction="row"
                  alignItems={"center"}
                >
                  <FormLabel>Вид испытаний: </FormLabel>
                  <FormControlLabel
                    value={"oos"}
                    control={<Radio color="secondary" />}
                    label="ООС"
                  />
                  <FormControlLabel
                    value={"hp"}
                    control={<Radio color="secondary" />}
                    label="ХП"
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        </FastField>

        <PlotAutoComplete
          name={FIELD_NAME.PLOT}
          handleChange={handleChangePlot}
          required={true}
        />

        <Field name={FIELD_NAME.SPECIALIZATION}>
          {({ field, meta }) => (
            <FormControl
              fullWidth
            >
              <TextField
                {...field}
                type="text"
                label={"Специализация"}
                fullWidth
                size="small"
              />
              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>

        <Field name={FIELD_NAME.GROUP}>
          {({ field, meta }) => (
            <FormControl
              fullWidth
            >
              <TextField
                {...field}
                type="text"
                label={"Группа  "}
                fullWidth
                size="small"
              />
              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>

        <Field type='checkbox' name={FIELD_NAME.IS_STANDARD}>
          {({ field }) => (
            <FormControlLabel
              {...field}
              sx={{ width: 'fit-content' }}
              control={<Checkbox />}
              label='Стандарт'
              onChange={handleChangeIsStandart}
            />
          )}
        </Field>

        <Field name={FIELD_NAME.LEAST_SIGNIFICANT_DIFFERENCE}>
          {({ field, meta }) => (
            <FormControl
              fullWidth
            >
              <TextField
                {...field}
                type="text"
                label={"Наименьшая существенная разность"}
                fullWidth
                size="small"
              />
              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
      </Stack>

      <Divider />

      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          Отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default AddVarietyTrialForm;
