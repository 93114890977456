import yup from "@/utils/yup";

import { reqInterface, resInterface } from "@/api/interfaces";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    laboratoryId?: number;
    initialValues?: resInterface.ILaboratory;
  };
  onSubmit?: () => void;
}

export type TForm = reqInterface.ILaboratoryReq;

export enum FIELD_NAME {
  NAME = "name",
  FULL_NAME = "full_name"
}

export const validationSchema = yup.object().shape({
  [FIELD_NAME.NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Имя не может быть пустым'),
});
