export const QUERY_STRING_MICRO_ROUTES_MAIN = {
  DATA: {
    path: "data",
  },
  MORPH_FEATURES: {
    path: "morphological_features",
  },
  ECONOMICALLY_VALUABLE_SIGNS: {
    path: "economically_valuable_signs",
  },
  PARAMETERS: {
    path: "parameters",
  },
  DISEASES: {
    path: "diseases",
  },
  QUALITY_INDICATORS: {
    path: "quality_indicators",
  },
} as const;

export const INDICATOR_TYPE = {
  morphological: "morphological",
  economically_valuable: "economically_valuable",
  parameter: "parameter",
}
