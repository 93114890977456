import React from "react";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Formik, FormikHelpers } from "formik";
import AddLaboratoryForm from "./components/AddLaboratoryForm";
import {
  ERROR_MESSAGE,
  IForm,
  IFormik,
  initialValues,
  serializedValues,
  validationSchema,
} from "./const";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { SWR_KEYS } from "@/const";
import { TChemistryType } from "@/api/types";
import theme from "@/theme";

/**
 * Компонент модалки добавления / удаления лаборатории
 * @param props
 * @param props.planId - id плана 
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    planId: number;
    type: TChemistryType;
    nameLabel: string;
    countLabel: string;
    title: string;
    subtitle: string;
    initialValue: IForm;
  };
}

const AddLaboratory: React.FC<IProps> = (props) => {
  const { 
    api, 
    toastStore, 
    swrStore, 
    modalStore 
  } = useStores();

  const mutateLabList =
    swrStore.mutators[
      SWR_KEYS.getLaboratoriesListForPlan(
        props.modalProps.planId,
      )
    ];

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    const payload = {
      laboratory: values.laboratory.id,
      plots: values.plots.map(el => el.id),
    };
    api.planning
      .generateLabPlanVarieties(payload, props.modalProps.planId)
      .then((res) => {
        if (!res?.success) {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: ERROR_MESSAGE,
              severity: "error",
            },
          });
        }
      })
      .catch((error) => {
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorToString(error),
            severity: "error",
          },
        });
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateLabList && mutateLabList();
      });
    modalStore.close()
  };

  if (!props.modalProps.planId) {
    console.log("Не передан id плана");
    return null;
  }

  const values = props.modalProps.initialValue 
    ? serializedValues(props.modalProps.initialValue) 
    : initialValues;

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography
          variant="body2"
          color={theme.palette.blackAndWhite.gray}
        >
          {props.modalProps.subtitle}
        </Typography>
        <Typography variant="h2" fontSize={24}>
          {props.modalProps.title}
        </Typography>
      </Box>
      <Divider />
      <Formik
        initialValues={values}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => <AddLaboratoryForm {...props} />}
      />
    </Box>
  );
};

export default AddLaboratory;