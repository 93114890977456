import yup from "@/utils/yup";


export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    parameterId?: number;
    initialValues?: Partial<TForm>;
    non_field_errors?: string;
    varietyId?: number;
  };
  onSubmit?: () => void;
}

export enum PARAMETER_FIELD_NAME {
  UNIT = 'unit',
  VARIETY_TRIAL_PLOT = 'variety_trial_plot',
  YEAR_OF_TESTING = "year_of_testing",
  RATE = "rate",
  VALUE = "value",
}

interface IValue {
  id: number,
  name: string,
}

export type TForm = {
  unit: IValue | null,
  variety_trial_plot: IValue | null,
  year_of_testing?: number;
  rate: IValue & {unit: IValue}| null,
  value?: string;
}


export const mapValueToForm = (values) => {
  return {
    [PARAMETER_FIELD_NAME.YEAR_OF_TESTING]: values?.variety_trial_plot?.year_of_testing,
    [PARAMETER_FIELD_NAME.UNIT]: values?.unit ? {
      ...values?.unit,
      label: values?.unit?.name
    } : null,
    [PARAMETER_FIELD_NAME.VARIETY_TRIAL_PLOT]: values?.variety_trial_plot ? {
      ...values?.variety_trial_plot,
      label: values?.variety_trial_plot?.plot?.name
    } : null,
    [PARAMETER_FIELD_NAME.RATE]: values?.rate ? {
      ...values?.rate,
      label: `${values?.rate?.name} ${values?.rate?.unit?.name}`
    } : null,
    [PARAMETER_FIELD_NAME.VALUE]: values?.value,    
  }
};

export const mapFormToValue = (values: TForm, varietyID?: number) => {
  return {
      [PARAMETER_FIELD_NAME.YEAR_OF_TESTING]: values?.year_of_testing,
      [PARAMETER_FIELD_NAME.UNIT]: values.rate?.unit?.id,
      [PARAMETER_FIELD_NAME.VARIETY_TRIAL_PLOT]: values.variety_trial_plot?.id,
      [PARAMETER_FIELD_NAME.VALUE]: values.value,
      [PARAMETER_FIELD_NAME.RATE]: values.rate?.id,
      variety: varietyID,
  }
};

export const validationSchema = yup.object().shape({
  [PARAMETER_FIELD_NAME.YEAR_OF_TESTING]: yup.number().required(),
  [PARAMETER_FIELD_NAME.VARIETY_TRIAL_PLOT]: yup.mixed().required(),
  [PARAMETER_FIELD_NAME.RATE]: yup.mixed().required(),
  [PARAMETER_FIELD_NAME.VALUE]: yup.number().nullable(),
  [PARAMETER_FIELD_NAME.UNIT]: yup.mixed().nullable(),
});
