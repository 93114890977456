import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { LABORATORIES_HEADER_LABELS, LABORATORIES_KEYS } from "../const";

import { TLaboratoriesKeys } from "../interfaces";
import { EditingCellsLaboratories } from "../templates/Laboratories/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: ""
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить"
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const LaboratoriesColumns: IColumn<TLaboratoriesKeys>[] = [
  {
    label: LABORATORIES_HEADER_LABELS.name,
    key: LABORATORIES_KEYS.name,
    contentHeader: LABORATORIES_HEADER_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    key: "editing",
    label: LABORATORIES_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsLaboratories,
      styles: cellButtons
    }
  }
];
