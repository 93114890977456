import {
  IHpContextRes,
  IHpCultivarGroups,
  ITrial,
} from "@/api/interfaces/responses";
import {
  TRIAL_FIELD_NAME
} from "@/apps/EditTrial/components/HpTrial/const";

export const serializeContext = (context: IHpContextRes) => {
  return {
    ...context,
    light_zones: context.light_zones || [],
    regions: context.regions.sort((a, b) => a.number - b.number) || [],
    cultivar_usage_types: context.cultivar_usage_types || [],
    cultivar_groups: context.cultivar_usage_types ? context.cultivar_usage_types.reduce((acc, el) => {
      acc[el.id] = el.cultivar_groups;
      return acc;
    }, {}) : {} as Record<string, IHpCultivarGroups[]>,
  };
};

export const formToValueSerializer = (values) => {
  return {
    type_hp: values.type_hp,
    year_of_testing: values.year_of_testing,
    light_zones: values.light_zones,
    group: values.group,
    regions: values.regions,
    payment_type: values.payment_type, 
    branches: values.branches.map((i) => i.id) || [],
    add_lab_trial: values.add_lab_trial,
    lab_trial_year: values.lab_trial_year,
  };
};

/**
 * Из бекенда в форму
 * @param values
 */
export const valueToFormSerializer = (values: ITrial | undefined | null) => {
  return {
    [TRIAL_FIELD_NAME.YEAR_OF_TESTING]: values?.year_of_testing ? values?.year_of_testing : null,
    [TRIAL_FIELD_NAME.TYPE_HP]: values?.type_hp || "",
    [TRIAL_FIELD_NAME.REGIONS]: values?.regions ? values.regions.map(region => region.id) : [],
    [TRIAL_FIELD_NAME.LIGHT_ZONES]: values?.light_zones ?  values.light_zones.map(region => region.id) : [],
    [TRIAL_FIELD_NAME.GROUP]: values?.group?.id || "",
    [TRIAL_FIELD_NAME.USE_DIRECTION_ID]: values?.group ? values.group.usage_type.id : "",
    [TRIAL_FIELD_NAME.INSPECTION_ADDRESS]: values?.inspection_address ? {...values.inspection_address, label: values.inspection_address.one_line} : null,
    [TRIAL_FIELD_NAME.INSPECTIONS_NUMBER]: values?.inspections_number || "",
    [TRIAL_FIELD_NAME.PAYMENT_TYPE]: values?.payment_type || "",
    [TRIAL_FIELD_NAME.BRANCHES]: values?.branches ? values?.branches.map(branch => ({ id: branch.id, name: branch.name })) : [],
  };
};

