import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  ISummaryMachineries
} from "@/api/interfaces/responses";

interface IProps {
  data: ISummaryMachineries[]
}

const EquipmentTable: React.FC<IProps> = ({data}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Тип</TableCell>
            <TableCell align="right">
              Исправно, шт.
            </TableCell>
            <TableCell align="right">
              Требует ремонта, шт.
            </TableCell>
            <TableCell align="right">
              Не пригодно, шт.
            </TableCell>
            <TableCell align="right">Всего</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.type}>
              <TableCell component="th" scope="row">
                {row?.type}
              </TableCell>
              <TableCell align="right">
                {row?.operational}
              </TableCell>
              <TableCell align="right">
                {row?.requires_repair}
              </TableCell>
              <TableCell align="right">
                {row?.unfit}
              </TableCell>
              <TableCell align="right">
                {row?.total}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EquipmentTable;
