import React from "react";
import {
  FastField,
  Form,
  useFormikContext,
} from "formik";
import { Box } from "@mui/system";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  IFormik,
  LAB_FIELD_NAME,
} from "./const";
import CheckIcon from "@mui/icons-material/Check";

/**
 * Компонент формы испытания на ООС
 * @сomponent
 */
const LabTrialForm = () => {
  const formik = useFormikContext<IFormik>();

  /**
   * Метод выбора даты
   * @param value
   */
  const handleChangeDate = (value: Date) => {
    formik.setFieldValue(
      LAB_FIELD_NAME.YEAR_OF_TESTING,
      value,
    );
  };

  return (
    <Box p={3}>
      <Box sx={{ maxWidth: 762 }}>
        <Form noValidate>
          <Stack direction="column" spacing="20px">
            <Typography variant="h3">
              Данные испытания
            </Typography>
            <FastField
              name={LAB_FIELD_NAME.YEAR_OF_TESTING}
            >
              {({ field, meta }) => (
                <DatePicker
                  name={field.name}
                  value={field.value}
                  onChange={handleChangeDate}
                  label="Год испытания"
                  openTo="year"
                  views={["year"]}
                  slotProps={{
                    textField: {
                      size: "small",
                      required: true,
                      error: meta.touched && !!meta.error,
                      helperText:
                        meta.touched && meta.error,
                      onBlur: field.onBlur,
                    },
                  }}
                />
              )}
            </FastField>
            <FastField name={LAB_FIELD_NAME.PAYMENT_TYPE}>
              {({ field }) => (
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Тип оплаты
                  </FormLabel>
                  <RadioGroup {...field}>
                    <Stack direction="row">
                      <FormControlLabel
                        value="budget"
                        control={<Radio color="secondary" />}
                        label="Бюджет"
                      />
                      <FormControlLabel
                        value="paid"
                        control={<Radio color="secondary" />}
                        label="Платные"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            </FastField>
          </Stack>
          <Box mt={5}>
            <Button
              startIcon={<CheckIcon />}
              disabled={formik.isSubmitting}
              variant="contained"
              type="submit"
            >
              Готово
            </Button>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default LabTrialForm;
