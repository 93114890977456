import React, { lazy, useEffect } from "react";
import { observer } from "mobx-react";
import { useSearchParams } from "react-router-dom";

import {
  QS_KEYS,
  RIGHT_SIDEBAR_TYPES,
  RIGHT_SIDEBAR_TYPES_LEVELS,
} from "@/apps/RightSidebar/const";
import RightSidebarWrap
  from "@/apps/RightSidebar/components/RightSidebarWrap";

import { isNull, isUndefined } from "@/utils/helpers";

import useRightSidebar from "@/hooks/useRightSidebar";
import { useQuery, useStores } from "@/hooks";
import { MODULES } from "@/pages/Handbook/fixtures";
import { asyncComponent } from "react-async-component";
import { CircularProgress } from "@mui/material";

const Regions = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/regions"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const Statement = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Statement/FirstLevel"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const Planning = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Planning/FirstLevel"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const PlanningHp = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/PlanningHp/FirstLevel"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const CultivarField = asyncComponent({
  resolve: () => import("@/apps/RightSidebar/templates/CultivarField"),
  LoadingComponent: () => <CircularProgress />,
})

const ExpertAssessment = asyncComponent({
  resolve: () => import("@/apps/RightSidebar/templates/ExpertAssessment"),
  LoadingComponent: () => <CircularProgress />,
})

const ExpertAssessmentTrial = asyncComponent({
  resolve: () => import("@/apps/RightSidebar/templates/ExpertAssessmentTrial"),
  LoadingComponent: () => <CircularProgress />,
})

const CultivarFieldWork = asyncComponent({
  resolve: () => import("@/apps/RightSidebar/templates/CultivarFieldWork"),
  LoadingComponent: () => <CircularProgress />,
})

const CultivarFieldVariety = asyncComponent({
  resolve: () => import("@/apps/RightSidebar/templates/CultivarFieldVariety"),
  LoadingComponent: () => <CircularProgress />,
})

const CultivarFieldVarietyLab = asyncComponent({
  resolve: () => import("@/apps/RightSidebar/templates/CultivarFieldVarietyLab"),
  LoadingComponent: () => <CircularProgress />,
})

const CultivarFieldVarietyTestingExpertReview = asyncComponent({
  resolve: () => import("@/apps/RightSidebar/templates/CultivarFieldVarietyTestingExpertReview"),
  LoadingComponent: () => <CircularProgress />,
})

const Observation = asyncComponent({
  resolve: () => import("@/apps/RightSidebar/templates/Observation"),
  LoadingComponent: () => <CircularProgress />,
})

const Productivity = asyncComponent({
  resolve: () => import("@/apps/RightSidebar/templates/Productivity"),
  LoadingComponent: () => <CircularProgress />,
})

const Trial = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Statement/SecondLevel/trial"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const GSUdetailed = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Planning/SecondLevel"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const LabDetail = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Planning/SecondLevelLabDetail"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const Cultivar = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Cultivar/FirstLevel"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const Distribution = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Statement/SecondLevel/distribution"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const OffBudget = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/OffBudget/firstLevel"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const OffBudgetFact = asyncComponent({
  resolve: () =>
    import("@/apps/RightSidebar/templates/OffBudgetFact"),
  LoadingComponent: () => <CircularProgress />,
});

const AgriculturalWorks = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/AgriculturalWorks/firstLevel"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const AgriculturalWorksFields = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/AgriculturalWorksFields/firstLevel"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const Address = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/address"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const Duty = asyncComponent({
  resolve: () =>
    import("@/apps/RightSidebar/templates/Handbooks/duty"),
  LoadingComponent: () => <CircularProgress />,
});

const LightZone = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/lightZone"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const CultivarHandbook = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/cultivar"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const CoutriesHandbook = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/countries"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const Employee = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/employee"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const PostsHandbook = asyncComponent({
  resolve: () =>
    import("@/apps/RightSidebar/templates/Handbooks/posts"),
  LoadingComponent: () => <CircularProgress />,
});

const AgriculturalWorksVarietyField = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/AgriculturalWorksFields/secondLevel"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const OrderCultivar = asyncComponent({
  resolve: () =>
    import(
      "@/apps/RightSidebar/templates/OrderCultivar/FirstLevel"
    ),
  LoadingComponent: () => <CircularProgress />,
});

const OrderPlot = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/OrderCultivar/SecondLevel"
    ),
);

const Diseases = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/disease"
    ),
);

const Units = lazy(
  () =>
    import("@/apps/RightSidebar/templates/Handbooks/units"),
);

const Quantity = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/quantity"
    ),
);

const Remark = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/remark"
    ),
);

const Subject = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/subject"
    ),
);

const MaterialType = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/materialType"
    ),
);

const SeedArrival = lazy(
  () => import("@/apps/RightSidebar/templates/SeedArrival"),
);

const Organizations = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/organizations"
    ),
);

const Order = lazy(
  () =>
    import("@/apps/RightSidebar/templates/Handbooks/order"),
);

const Reproductions = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/reproductions"
    ),
);

const Indicators = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/indicator"
    ),
);

const Meth = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/methodology"
    ),
);

const Branches = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/branches"
    ),
);
const Varieties = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/varieties"
    ),
);
const Districts = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/districts"
  )
);

const VegetationStages = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/vegetationStages"
    ),
);

const Departments = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/departments"
    ),
);
const AgriculturalMachinery = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/agriculturalMachinery"
    ),
);

const TеchnologicalMaps = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/tеchnologicalMaps"
    ),
);

const WorkDetailed = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/components/TеchnologicalMaps/SecondLevel"
    ),
);
const CultivarUsageType = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/cultivarUsageType"
    ),
);
const CultivarGroup = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/cultivarGroup"
    ),
);   
const VegetationCauses = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/vegetationCauses"
    ),
);
const Plot = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/plot"
    ),
  );
const Chemistry = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/chemistry"
    ),
  );
  
const Expense = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/expense"
    ),
  );
const BudgetaruTrialCalc = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/budgetaryTrialCalc"
    ),
);
const Rate = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/rate"
    ),
);
const Agreement = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/agreement"
    ),
);
const Parameter = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/parameter"
    ),
);
const Laboratories = lazy(
  () =>
    import(
      "@/apps/RightSidebar/templates/Handbooks/laboratories"
    ),
);

const ProductivityTestingExpertReview = asyncComponent({
  resolve: () => import("@/apps/RightSidebar/templates/ProductivityTestingExpertReview"),
  LoadingComponent: () => <CircularProgress />,
})

const content = {
  // !!! Внешние модули
  [RIGHT_SIDEBAR_TYPES.EMPTY]: () => null,
  [RIGHT_SIDEBAR_TYPES.STATEMENT]: Statement,
  [RIGHT_SIDEBAR_TYPES.PLANNING_SIDEBAR]: Planning,
  [RIGHT_SIDEBAR_TYPES.PLANNING_HP]: PlanningHp,
  [RIGHT_SIDEBAR_TYPES.SEED_ARRIVAL]: SeedArrival,
  [RIGHT_SIDEBAR_TYPES_LEVELS[
    RIGHT_SIDEBAR_TYPES.PLANNING_SIDEBAR
  ].GSU_DETAIL]: GSUdetailed,
  [RIGHT_SIDEBAR_TYPES_LEVELS[
    RIGHT_SIDEBAR_TYPES.PLANNING_SIDEBAR
  ].LAB_DETAIL]: LabDetail,
  [RIGHT_SIDEBAR_TYPES_LEVELS[RIGHT_SIDEBAR_TYPES.STATEMENT]
    .TRIAL]: Trial,
  [RIGHT_SIDEBAR_TYPES_LEVELS[RIGHT_SIDEBAR_TYPES.STATEMENT]
    .DISTRIBUTION]: Distribution,
  [RIGHT_SIDEBAR_TYPES.OFFBUDGET_SIDEBAR]: OffBudget,
  [RIGHT_SIDEBAR_TYPES.OFFBUDGET_SIDEBAR_FACT]:
    OffBudgetFact,
  [RIGHT_SIDEBAR_TYPES.CULTIVAR]: Cultivar,
  [RIGHT_SIDEBAR_TYPES.AGRICULTURAL_WORKS]:
    AgriculturalWorks,
  [RIGHT_SIDEBAR_TYPES.AGRICULTURAL_WORKS_FIELDS]:
    AgriculturalWorksFields,
  [RIGHT_SIDEBAR_TYPES_LEVELS[
    RIGHT_SIDEBAR_TYPES.AGRICULTURAL_WORKS_FIELDS
  ].VARIETY]: AgriculturalWorksVarietyField,
  [RIGHT_SIDEBAR_TYPES.ORDER_CULTIVAR]: OrderCultivar,
  [RIGHT_SIDEBAR_TYPES_LEVELS[
    RIGHT_SIDEBAR_TYPES.ORDER_CULTIVAR
  ].PLOT]: OrderPlot,
  [RIGHT_SIDEBAR_TYPES.CULTIVAR_FIELD]: CultivarField,
  [RIGHT_SIDEBAR_TYPES_LEVELS[RIGHT_SIDEBAR_TYPES.CULTIVAR_FIELD].CULTIVAR_FIELD_WORK]: CultivarFieldWork,
  [RIGHT_SIDEBAR_TYPES.CULTIVAR_FIELD_VARIETY]: CultivarFieldVariety,
  [RIGHT_SIDEBAR_TYPES.CULTIVAR_FIELD_VARIETY_LAB]: CultivarFieldVarietyLab,
  [RIGHT_SIDEBAR_TYPES.CULTIVAR_FIELD_VARIETY_LAB_TESTING_EXPERT_REVIEW]: CultivarFieldVarietyTestingExpertReview,
  [RIGHT_SIDEBAR_TYPES_LEVELS[RIGHT_SIDEBAR_TYPES.CULTIVAR_FIELD_VARIETY].OBSERVATION]: Observation,
  [RIGHT_SIDEBAR_TYPES_LEVELS[RIGHT_SIDEBAR_TYPES.CULTIVAR_FIELD_VARIETY].PRODUCTIVITY]: Productivity,
  [RIGHT_SIDEBAR_TYPES_LEVELS[RIGHT_SIDEBAR_TYPES.HANDBOOK].TECH_WORK_SIDEBAR]: WorkDetailed,
  [RIGHT_SIDEBAR_TYPES.EXPERT_ASSESSMENT]: ExpertAssessment,
  [RIGHT_SIDEBAR_TYPES_LEVELS[RIGHT_SIDEBAR_TYPES.EXPERT_ASSESSMENT].TRIAL]: ExpertAssessmentTrial,
  [RIGHT_SIDEBAR_TYPES.PRODUCTIVITY_TESTING_EXPERT_REVIEW]: ProductivityTestingExpertReview,

  // !!! Справочники
  [MODULES.addres.module]: Address,
  [MODULES.light_zone.module]: LightZone,
  [MODULES.cultivars.module]: CultivarHandbook,
  [MODULES.countries.module]: CoutriesHandbook,
  [MODULES.employee.module]: Employee,
  [MODULES.posts.module]: PostsHandbook,
  [MODULES.quantity.module]: Quantity,
  [MODULES.disease.module]: Diseases,
  [MODULES.units.module]: Units,
  [MODULES.material_type.module]: MaterialType,
  [MODULES.remark.module]: Remark,
  [MODULES.subject.module]: Subject,
  [MODULES.organizations.module]: Organizations,
  [MODULES.order.module]: Order,
  [MODULES.regions.module]: Regions,
  [MODULES.reproductions.module]: Reproductions,
  [MODULES.indicators.module]: Indicators,
  [MODULES.methodology.module]: Meth,
  [MODULES.duty.module]: Duty,
  [MODULES.branches.module]: Branches,
  [MODULES.tеchnologicalMaps.module]: TеchnologicalMaps,
  [MODULES.departments.module]: Departments,
  [MODULES.varieties.module]: Varieties,
  [MODULES.districts.module]: Districts,
  [MODULES.vegetation_stages.module]: VegetationStages,
  [MODULES.agricultural_machinery.module]: AgriculturalMachinery,
  [MODULES.cultivar_usage_type.module]: CultivarUsageType,
  [MODULES.cultivar_group.module]: CultivarGroup,
  [MODULES.vegetation_causes.module]: VegetationCauses,
  [MODULES.plot.module]: Plot,
  [MODULES.chemistry.module]: Chemistry,
  [MODULES.expense.module]: Expense,
  [MODULES.budgetary_trials_calc.module]: BudgetaruTrialCalc,
  [MODULES.rate.module]: Rate,
  [MODULES.agreement.module]: Agreement,
  [MODULES.parameters.module]: Parameter,
  [MODULES.laboratories.module]: Laboratories,
};

const RightSidebar = () => {
  const [, setSearchParams] = useSearchParams();
  const { queryStringSidebarCollector } = useStores();
  const query = useQuery<any>();
  const {
    type,
    handleClose,
    handleOpen,
    handleCloseInstantly,
    sidebarProps,
  } = useRightSidebar();

  const CurrentContent =
    content[type ?? RIGHT_SIDEBAR_TYPES.EMPTY];

  // закрытие правого сайдбара с очисткой параметров
  const handleCloseWithCleanQuery = () => {
    handleClose();
    if (location.search.includes("keySidebar")) {
      const queryParams =
        queryStringSidebarCollector.clear();
      setSearchParams(queryParams);
    }
  };

  useEffect(() => {
    if (!Object.keys(query).length) {
      try {
        handleCloseInstantly();
      } catch (err) {
        console.error("[NO FIND QUERY]");
      }
    }

    if (query[QS_KEYS.KEY_SIDEBAR_TYPE]) {
      try {
        const params = queryStringSidebarCollector.init();

        const querySetup = {
          type: params.module as string,
          modalProps: params.modalProps as any,
        };

        handleOpen(querySetup);
      } catch (err) {
        console.error("[NO FIND QUERY]");
      }
    }
  }, [location.search]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        handleCloseWithCleanQuery();
        event.preventDefault();
      }
    };
    document.addEventListener("keyup", keyDownHandler);
    return () => {
      document.removeEventListener("keyup", keyDownHandler);
      handleCloseInstantly();
    };
  }, []);

  if (
    isNull(CurrentContent) ||
    isUndefined(CurrentContent)
  ) {
    return null;
  }

  return (
    <RightSidebarWrap>
      <CurrentContent
        onClose={handleCloseWithCleanQuery}
        contentProps={sidebarProps}
      />
    </RightSidebarWrap>
  );
};

export default observer(RightSidebar);
