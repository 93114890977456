import React, { useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import { EditIcon, DeleteIcon } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { SWR_KEYS } from "@/const";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";
import { ILaboratory } from "@/api/interfaces/responses";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};
/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCellsLaboratories = (
  _: string,
  cell: ILaboratory,
  itemsInPage: ILaboratory[],
): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [isOpen, setIsOpen] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);

  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const handleClickEdit = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    const initialValues = {
      ...cell,
    };
    modalStore.open(VARIANT_MODAL.LABORATORIES, {
      theme: THEME_MODAL.W_555,
      initialValues,
      laboratoryId: cell.id,
      isChange: true,
    });
    setIsOpen(null);
  };

  /**
   * чтобы из деталки понимать, что на странице единственный элемент делаем
   * query парам - last = true
   */
  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else if (query?.last) {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  const mutateList =
    swrStore.mutators[SWR_KEYS.getLaboratoriesList()];

  const deleteWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDelete = (resolve, reject) => {
    api.regulatoryInfo
      .deleteLaboratory(cell.id)
      .then(() => {
        deleteWithCheckingLast();
        resolve();
      })
      .catch(reject);
    setIsOpen(null);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const canEditLaboratory = userStore.isPermission(
    PERMISSIONS.regulatory_infoLaboratory,
    PERMISSION_CRUD.change
  )
  const canDeleteLaboratory = userStore.isPermission(
    PERMISSIONS.regulatory_infoLaboratory,
    PERMISSION_CRUD.delete
  )

  if (!canEditLaboratory && !canDeleteLaboratory) {
    return <></>
  }


  return (
    <Box>
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={isOpen}
        open={open}
        onClose={handleClose}
      >
        {canEditLaboratory && 
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon />
          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
        {canEditLaboratory && canDeleteLaboratory && 
        <Divider />
        }
        {canDeleteLaboratory && 
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color={"error"} />
          <Typography color="error">
            {EDITING_CELL.delete}
          </Typography>
        </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};
