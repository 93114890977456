import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Table from "@/apps/Table";
import { observer } from "mobx-react";
import { useQuery, useStores } from "@/hooks";
import {
  IPaginatedResult,
  IReproductionListItem,
} from "@/api/interfaces/responses";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { SWR_KEYS } from "@/const";
import { IErrorResponse } from "@/interfaces";
import useSWR, { SWRResponse } from "swr";
import {
  IPropsSidebar,
  TReproductionTypeKeys,
} from "../../interfaces";
import AddIcon from "@mui/icons-material/Add";
import qs from "qs";
import Filters from "@/components/Filters";
import { Box, Button } from "@mui/material";

import { IFilter } from "@/interfaces";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { isUndefined } from "@/utils/helpers";
import { ReproductionColumns } from "../../models/reproductions";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const getFilters = (): IFilter[] => {
  return [];
};

/**
 * Компонент, представляющий таблицу адрессов.
 * @returns {ReactElement}
 */
const ReproductionsTable: React.FC<IPropsSidebar> = ({
  handleOpenRightSidebar,
}): ReactElement => {
  const { api, swrStore, modalStore, userStore } = useStores();
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [queryParams, setQueryParams] =
    useState<any>(query);

  // переменная которая показывает применены ли фильтры
  const isFilters: boolean =
    Object.keys(queryParams).length > 0;
  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orgeringArray);
  const {
    data,
    error,
    isLoading,
    mutate,
  }: SWRResponse<
    IPaginatedResult<IReproductionListItem>,
    IErrorResponse
  > = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      date_range_after: queryParams.date_range_after,
      date_range_before: queryParams.date_range_before,
      search: queryParams.search,
      executor: queryParams.executor?.key,
      status: queryParams.status?.key,
      key: SWR_KEYS.getReproductionList(),
    },
    api.regulatoryInfo.getReproductionList,
  );

  const filters = getFilters();

  const handleOpenCreateModal = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.REPRODUCTIONS, {
      theme: THEME_MODAL.W_555,
    });
  };

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  useEffect(() => {
    setQueryParams(query);
  }, [location.search]);

  swrStore.addMutator(SWR_KEYS.getReproductionList(), mutate);

  const canAddReproduction = userStore.isPermission(
    PERMISSIONS.regulatory_infoReproduction,
    PERMISSION_CRUD.add
  )

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
        />
        {canAddReproduction && 
        <Button
          sx={{
            whiteSpace: "nowrap",
            flexShrink: "0",
          }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenCreateModal}
        >
          Добавить запись
        </Button>
        }
      </Box>

      <Table<IReproductionListItem, TReproductionTypeKeys>
        columns={ReproductionColumns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={"Добавить запись"}
        textEmptyTableWithFilter={
          "Не найдено ни одной записи"
        }
        textEmptyTable={"Еще не создано ни одной записи"}
        onRowClick={handleOpenRightSidebar}
        emptyTableAddEntity={handleOpenCreateModal}
        overflowX="scroll"
        overflowY="scroll"
      />
    </Fragment>
  );
};

export default observer(ReproductionsTable);

