import React from "react";
import { Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Box, Button, Divider, } from "@mui/material";
import { FIELDS_NAME, IFormik } from "../const";
import { useStores } from "@/hooks";
import { DatePickerField } from "@/components/Fields";

const SelectDateForm = () => {
  const { modalStore } = useStores();
  const formik = useFormikContext<IFormik>();

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <DatePickerField
          name={FIELDS_NAME.DATE}
          required
          label="Дата закладки"
          fullWidth={true}
        />
      </Stack>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red"
          onClick={() => modalStore.close()}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
        >
          Готово
        </Button>
      </Box>
    </Form>
  );
};

export default SelectDateForm;
