import React from "react";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { HP_TYPE, PAGE_SIZE, SWR_KEYS, TRIAL_TYPE } from "@/const";
import { ITrial } from "@/api/interfaces/responses";
import { TPlanningTrialType } from "@/api/types";
import { ITrialListReq } from "@/api/interfaces/requests";

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    cultivarId: number;
    trialType?: TPlanningTrialType
    year?: number;
    onSubmit: (payload: ITrial[]) => void
    initialValue?: ITrial[]
    key: string
  };
}

/** Модалка с выбором испытаний */

const SelectTrials: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps
}) => {
  const { api } = useStores();
  const handleAccept = (value: ITrial[]) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handlePop();
  };
  const fetcher = (args: ITrialListReq) => {
    const {
      page,
      search,
      cultivar,
      year_of_testing,
      trial_type,
      type_hp,
      statement_status,
    } = args;
    return api.trial.getTrialList({
      page,
      page_size: PAGE_SIZE,
      search,
      cultivar,
      year_of_testing,
      trial_type,
      type_hp,
      statement_status,
    });
  };
  const getLabel = (value: ITrial): string => value.name;
  const getMeta = (value: ITrial) => String(value.id);

  const swrKey = SWR_KEYS.getTrialList();

  const props = {
    initialValue: modalProps.initialValue,
    key: [swrKey],
    title: "Выберите испытание",
    q: {
      cultivar: modalProps.cultivarId,
      trial_type: modalProps.trialType,
      type_hp: modalProps.trialType === TRIAL_TYPE.HP 
        ? HP_TYPE.ACCORDING_REGIONS_RF 
        : undefined,
      year_of_testing: modalProps.year,
      statement_status: "accepted",
    },
    handleAccept,
    saveButtonLabel: "Добавить",
    listTitle: "Сорта",
    getLabel: getLabel,
    getMeta: getMeta,
    fetcher,
  };

  return (
    <AddEntities<ITrial>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectTrials;