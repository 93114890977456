export const CULTIVAR_FIELD_VARIETY_LAB_KEYS = {
  laboratoryName: "lab_plan_plot__laboratory__name",
  plotName: "lab_plan_plot__plot__name",
  varietyName: "variety__name",
  cultivarName: "plan__cultivar__name",
  year: "trial__year_of_testing",
  status: "status",
  editing: "editing",
} as const;

export const CULTIVAR_FIELD_VARIETY_LAB_LABELS = {
  laboratory: "Лаборатория",
  plot: "ГСУ (Отправитель)",
  year: "Год",
  cultivar: "Культура",
  variety: "Сорт",
  status: "Статус",
  editing: "editing",
};

export const EMPTY_LIST_TEXT =
  "Еще не создано ни одного лабораторного опыта";
export const EMPTY_LIST_TEXT_WITH_FILTERS =
  "По указанным параметрам лабораторные опыты не найдены";
export const ADD_TEXT = "Добавить";