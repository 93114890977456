import React, { useEffect, useState } from "react";
import { resInterface } from "@/api/interfaces";
import { useSearchParams } from "react-router-dom";
import { useQuery, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import qs from "qs";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Box, Divider, IconButton, Menu, Typography } from "@mui/material";
import { EditIcon, DeleteIcon } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { truncateString } from "@/utils/helpers";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  delete: "Удалить",
};


export const СultivarCell = (
    _: string,
    cell: resInterface.IVegetationStage,
): JSX.Element => {
    const { cultivar } = cell;
    return (
        <Box>
            <Typography fontSize={14}>
                {cultivar?.name ?? "-"}
            </Typography>
        </Box>
    );
};

export const DescriptionCell = (
    _: string,
    cell: resInterface.IVegetationStage,
): JSX.Element => {
    const { description } = cell;
    return (
      <Box>
      <Typography fontSize={14}>{truncateString(description, 50)}</Typography>
      </Box>
    );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCellsVegetationStage = (_: string, cell: any, itemsInPage: any[]): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: 'true' },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else if (query?.last) {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    const initialValues = {
      ...cell,
      cultivar: {
        ...cell?.cultivar,
        label: cell?.cultivar?.name,
      },
    };
    event.stopPropagation();
    modalStore.open(VARIANT_MODAL.VEGETATION_STAGES, {
      theme: THEME_MODAL.W_555,
      initialValues: initialValues,
      vegetationStageId: cell.id,
    });
    setAnchorEl(null);
  };

  const mutateList =
    swrStore.mutators[SWR_KEYS.getVegetationStageList()];

  const mutateWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };


  const handleDelete = (resolve, reject) => {
    api.regulatoryInfo.deleteVegetationStage(cell.id).then(() => {
      mutateWithCheckingLast();
      resolve();
    }).catch(reject);
    setAnchorEl(null);
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
  };

  const canEditVegStage= userStore.isPermission(
    PERMISSIONS.regulatory_infoVegetationStage,
    PERMISSION_CRUD.change
  )
  const canDeleteVegStage = userStore.isPermission(
    PERMISSIONS.regulatory_infoVegetationStage,
    PERMISSION_CRUD.delete
  )

  if (!canEditVegStage && !canDeleteVegStage) {
    return <></>
  }

  return (
    <Box>
      <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {canEditVegStage && 
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon />
          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
        {canEditVegStage && canDeleteVegStage && 
        <Divider />
        }
        {canDeleteVegStage && 
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color={"error"} />
          <Typography color='error'>{EDITING_CELL.delete}</Typography>
        </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};
