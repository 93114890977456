import React, { ComponentType, useLayoutEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import useSWR from "swr";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores } from "@/hooks";
import {
  ENUM_FILD_TO_RU_NAME,
  IFormik,
  initialValues,
  serializeValues,
  validationSchema
} from "./const";
import { FormikProps, FormikValues } from "formik/dist/types";
import { TYPES_MAP_OBJECT } from "@/apps/Map/const";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString, getListErrors, getRandomColor } from "@/utils/helpers";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { SWR_KEYS } from "@/const";
import Loader from "@/components/Loader";
import { CircularProgress } from "@mui/material";
import { asyncComponent } from "react-async-component";

const AddAreaPage = () => {
  const { id } = useParams();
  const { headerStore, api, toastStore, swrStore, mapStore } = useStores();
  const navigate = useNavigate();
  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };
  const { data, isLoading, mutate, error } = useSWR(
    id ? SWR_KEYS.getAgriculturalLabourLocation(id) : null,
    () => api.agriculturalLabour.getLocationById(id as string),
    {
      onError: showToast
    }
  );
  const mutateList =
    swrStore.mutators[SWR_KEYS.getAgriculturalLabourLocationList()];

  useLayoutEffect(() => {
    headerStore.setHeader(VARIANT_HEADER.ADD_AREA);
  }, []);

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {
    if (values.coordinates.length) {
      values.coordinates.push(values.coordinates[0] as unknown as any);
    }
    const data = {
      name: values.name,
      plot: values.plot?.id as number,
      colour: values.colour,
      map_object: values.coordinates.length
        ? JSON.stringify({
            type: TYPES_MAP_OBJECT.Polygon,
            coordinates: [values.coordinates.map((el) => [el[1], el[0]])]
          })
        : null,
      total_area: values?.total_area || "",
      agricultural_land: values?.agricultural_land,
      arable_land: values.arable_land
    };
    const promise = id
      ? api.agriculturalLabour.updateLocation(id, data)
      : api.agriculturalLabour.createLocation(data);
    promise
      .then(() => {
        navigate(ROUTES_PATHS.agriculturalWork);
        mutateList && mutateList();
        id && mutate();
      })
      .catch((error) => {
        if(error?.response?.status === 400) {
          const data = getListErrors(error.response.data, ENUM_FILD_TO_RU_NAME);
          error.response.data = data;
        }
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorToString(error, true),
            allowHtml: true,
            severity: "error"
          }
        });
      })
      .finally(() => {
        mapStore.setInitialValuesForms({});
        mapStore.setTempPolygonPoints([]);
        helpers.setSubmitting(false);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return null;
  }

  const AreaData = asyncComponent({
    resolve: () => import("@/pages/AddArea/components/AreaData"),
    LoadingComponent: () => <CircularProgress />
  });

  const values = id
    ? serializeValues(data)
    : { ...initialValues, colour: getRandomColor() };

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      component={AreaData as ComponentType<FormikProps<FormikValues>>}
    />
  );
};

export default AddAreaPage;
