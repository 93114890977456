import React, { useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { FormikHelpers } from "formik";
import useSWR from "swr";
import {
  KEYS_SIDEBAR_VARIANT_MODULES,
  SWR_KEYS,
  TRIAL_TYPE
} from "@/const";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores } from "@/hooks";
import FormikStepper from "@/components/FormikStepper";
import DataBlock
  from "@/pages/AddObservation/components/DataBlock";
import {
  initialValues,
  IFormik,
  indicatorsValidationSchema,
  FIELDS_NAME,
  serializeFormToValue,
  diseasesValidationSchema,
  weatherConditionsValidationSchema,
  serializeValueToForm,
  dataValidationSchema,
  indicatorsValidationSchemaHp
} from "@/pages/AddObservation/const";
import { FormikValues } from "formik/dist/types";
import { isUndefined } from "@/utils/helpers";
import Indicators
  from "@/pages/AddObservation/components/Indicators";
import { Box, CircularProgress } from "@mui/material";
import Files from "@/pages/AddObservation/components/Files";
import Diseases
  from "@/pages/AddObservation/components/Diseases";
import WeatherConditions
  from "@/pages/AddObservation/components/WeatherConditions";
import { TYPE_TESTING } from "../TestingExpertReview/const";

const AddObservationPage = () => {
  const {
    queryStringSidebarCollector,
    headerStore,
    api,
    swrStore
  } = useStores();
  const navigate = useNavigate();
  const { id, observationId } = useParams();
  const [searchParams] = useSearchParams();
  const typeTesting = searchParams.get("typeTesting");
  const isExpertReview = typeTesting === TYPE_TESTING.EXPERT_REVIEW ? true : false

  const fetcher = ([, id]: [string, string | undefined]) => {
    if (isUndefined(id)) return null;
    return api.agriculturalLabour.getObservationById(id);
  };

  const mutateList = swrStore.mutators[SWR_KEYS.getObservationList()]

  const { data, isLoading, mutate } = useSWR(
    ["fetchObservationById", observationId],
    fetcher, {}
  );

  const cultivarFetcher = ([, id]: [string, string | undefined]) => {
    if (isUndefined(id)) return null;
    return api.agriculturalLabour.getCultivarFieldVarietyById(id);
  };

  const { data: varietyData, isLoading: isVarietyLoading } = useSWR(
    [SWR_KEYS.cultivarFieldVariety(), id],
    cultivarFetcher, {}
  );

  const handleBack = (id?: number | string) => {
    if (id) {
      const { str } = queryStringSidebarCollector.setup({
        tab: undefined,
        keyContent: isExpertReview
          ? KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY_LAB_TESTING_EXPERT_REVIEW
          : KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY,
        module_id: id,
      });
      navigate({
        pathname: isExpertReview
          ? ROUTES_PATHS.testingExpertReview
          : ROUTES_PATHS.cultivarFieldVarietyList,
        search: `?${str}`
      });
    } else {
      navigate(ROUTES_PATHS.cultivarFieldVarietyList);
    }
  };
  const title = observationId ? "Редактировать наблюдение" : "Добавить наблюдение";

  const cultivarId = varietyData?.variety?.cultivar?.id

  const cultivarFieldTrialType = varietyData?.cultivar_field?.type_trial

  const handleSubmit = (values: IFormik, helpers: FormikHelpers<FormikValues>) => {
    const {
      file_list,
      diseases,
      indicators,
      weather_resistances,
      off_type_plants_number,
      uniformity,
      indicator_value,
      indicator,
      ...data
    } = serializeFormToValue(values);


    const formData = new FormData();
    for (const key in data) {
      if (!isUndefined(data[key])) {
        formData.append(key, data[key]);
      }
    }
    formData.append("cultivar_field_variety", id as string);
    if (diseases) {
      diseases.forEach((el, index) => {
        formData.append(`${FIELDS_NAME.DISEASES}[${index}]${FIELDS_NAME.DISEASE}`, String(el.disease));
        formData.append(`${FIELDS_NAME.DISEASES}[${index}]${FIELDS_NAME.DAMAGE}`, el.damage);
        formData.append(`${FIELDS_NAME.DISEASES}[${index}]${FIELDS_NAME.UNIT}`, String(el.unit));
      });
    }
    if (indicators && cultivarFieldTrialType !== TRIAL_TYPE.HP && cultivarFieldTrialType !== TRIAL_TYPE.PHYTO && !isExpertReview) {
      indicators.forEach((el, index) => {
        formData.append(`${FIELDS_NAME.INDICATORS}[${index}]variant_indicator_methodology`, String(el));
      });
    }
    if (indicator && (cultivarFieldTrialType === TRIAL_TYPE.HP || cultivarFieldTrialType ===  TRIAL_TYPE.PHYTO || isExpertReview)) {
      indicator.forEach((el, index) => {
        formData.append(`${FIELDS_NAME.INDICATORS}[${index}]indicator`, String(el));
      });
    }
    if (off_type_plants_number && cultivarFieldTrialType === TRIAL_TYPE.OOS) {
      off_type_plants_number.forEach((el, index) => {
        formData.append(`${FIELDS_NAME.INDICATORS}[${index}]off_type_plants_number`, String(el ? el : ""))
      });
    }
    if (indicator_value && (cultivarFieldTrialType === TRIAL_TYPE.HP || cultivarFieldTrialType === TRIAL_TYPE.PHYTO || isExpertReview)) {
      indicator_value.forEach((el, index) => {
        formData.append(`${FIELDS_NAME.INDICATORS}[${index}]indicator_value`, String(el))
      });
    }
    if (uniformity && cultivarFieldTrialType === TRIAL_TYPE.OOS) {
      uniformity.forEach((el, index) => {
        formData.append(`${FIELDS_NAME.INDICATORS}[${index}]uniformity`, String(!!el))
      });
    }
    if (weather_resistances) {
      weather_resistances.forEach((el, index) => {
        formData.append(`${FIELDS_NAME.WEATHER_CONDITIONS}[${index}]${FIELDS_NAME.WEATHER_CONDITION}`, String(el.weather_condition));
        formData.append(`${FIELDS_NAME.WEATHER_CONDITIONS}[${index}]${FIELDS_NAME.RATING}`, String(el.rating));
      });
    }
    if (!observationId) {
      file_list.forEach((file) => {
        formData.append(FIELDS_NAME.FILE_LIST, file);
      });
    }
    helpers.setSubmitting(true);
    const promise = observationId ? api.agriculturalLabour.patchObservationById(observationId, formData) : api.agriculturalLabour.postObservation(formData);
    promise.then(() => {
      mutateList && mutateList()
      handleBack(id);
    }).finally(() => {
      void mutate();
      helpers.setSubmitting(false);
    });
  };

  useLayoutEffect(() => {
    headerStore.setProps({
      title: title,
      handleBack: () => handleBack(id)
    });
    headerStore.setHeader(VARIANT_HEADER.DEFAULT);
  }, [id, title]);

  if (isLoading || isVarietyLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  const values = (observationId && data) ? serializeValueToForm(data) : initialValues;

  return (
    <FormikStepper initialValues={values}
      onSubmit={handleSubmit}
    >
      <FormikStepper.FormikStep label="Данные" validationSchema={dataValidationSchema}>
        <DataBlock cultivarId={cultivarId}/>
      </FormikStepper.FormikStep>
      {
        !observationId && (
          <FormikStepper.FormikStep label={cultivarFieldTrialType === TRIAL_TYPE.HP || cultivarFieldTrialType ===  TRIAL_TYPE.PHYTO || isExpertReview === true ? "Параметры" :"Признаки"}
            validationSchema={cultivarFieldTrialType === TRIAL_TYPE.HP || cultivarFieldTrialType ===  TRIAL_TYPE.PHYTO || isExpertReview === true ? indicatorsValidationSchemaHp : indicatorsValidationSchema}>
            <Indicators trialType={cultivarFieldTrialType} isExpertReview={isExpertReview} cultivarId={cultivarId} />
          </FormikStepper.FormikStep>
        )
      }
      {
        !observationId && (
          <FormikStepper.FormikStep label="Болезнь/Вредители"
            validationSchema={diseasesValidationSchema}>
            <Diseases />
          </FormikStepper.FormikStep>
        )
      }
      {
        !observationId && (
          <FormikStepper.FormikStep label="Метеоустойчивость"
            validationSchema={weatherConditionsValidationSchema}>
            <WeatherConditions />
          </FormikStepper.FormikStep>
        )
      }
      <FormikStepper.FormikStep label="Фотографии">
        <Files />
      </FormikStepper.FormikStep>
    </FormikStepper>
  );
};

export default AddObservationPage;