import yup from "@/utils/yup";
import {
  IVegetationCause,
  ILaboratory,
  IVariety
} from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  LABORATORY: "laboratory",
  CULTIVAR: "cultivar",
  VARIETY: "variety",
  YEAR: "year",
} as const;

export interface IFormik {
  [FIELDS_NAME.LABORATORY]: ILaboratory | null,
  [FIELDS_NAME.CULTIVAR]?: IVegetationCause | null,
  [FIELDS_NAME.VARIETY]: IVariety | null,
  [FIELDS_NAME.YEAR]: number | null,
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.LABORATORY]: yup.mixed().required(),
  [FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
  [FIELDS_NAME.VARIETY]: yup.mixed().required(),
  [FIELDS_NAME.YEAR]: yup.number().required(),
});

export const initialValues = {
  [FIELDS_NAME.LABORATORY]: null,
  [FIELDS_NAME.CULTIVAR]: null,
  [FIELDS_NAME.VARIETY]: null,
  [FIELDS_NAME.YEAR]: null,
};
