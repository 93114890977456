import React, { useEffect, useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Alert, Box, Button, Divider, FormControl, FormHelperText, } from "@mui/material";
import { FIELDS_NAME, IFormik } from "../const";
import { useInfiniteScroll, useStores } from "@/hooks";
import {
  CultivarAutocomplete,
  VarietyAutocomplete,
} from "@/components/Autocompletes";
import { YearDatePicker } from "@/components/Fields";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import { SWR_KEYS } from "@/const";
import Autocomplete from "@/components/Autocomplete";

const DownloadCultivarFieldForm = () => {
  const { modalStore, api, swrStore } = useStores();
  const formik = useFormikContext<IFormik>();
  const [search, setSearch] = useState("");

  const swrKey = SWR_KEYS.getLaboratoriesList();
  const getKeyOrganization = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    search: search,
  });

  const { data, handleScroll, mutate, isEmpty } =
    useInfiniteScroll(
      getKeyOrganization,
      api.regulatoryInfo.getLaboratoriesList,
      {
        revalidateFirstPage: false,
      },
    );

  const handleChange = (event, child) => {
    formik.setFieldValue(FIELDS_NAME.LABORATORY, child);
  };

  useEffect(() => {
    swrStore.addMutator(swrKey, mutate);
  }, []);


  console.log(formik.values, "formik.values")

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>

        <Field name={FIELDS_NAME.LABORATORY}>
          {({ field, meta }) => (
            <FormControl required>
              <Autocomplete
                {...field}
                onChange={handleChange}
                label={"Лаборатория"}
                required={true}
                data={data}
                handleScroll={handleScroll}
                search={setSearch}
                error={meta.touched && !!meta.error}
              />

              {isEmpty && (
                <Alert sx={{ mt: 2 }} severity="warning">
                  Справочник лаборатории не заполнен
                </Alert>
              )}

              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>

        <CultivarAutocomplete
          name={FIELDS_NAME.CULTIVAR} 
        />
        <VarietyAutocomplete
          name={FIELDS_NAME.VARIETY}
          q={{
            cultivar: formik.values[FIELDS_NAME.CULTIVAR]?.id
          }}
          disabled={!formik.values[FIELDS_NAME.CULTIVAR]?.id}
          required 
        />
        <YearDatePicker 
          name={FIELDS_NAME.YEAR}
          fullWidth={true} 
          required label="Год" 
        />
      </Stack>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red"
          onClick={() => modalStore.close()}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<FileDownloadOutlinedIcon />}
          disabled={formik.isSubmitting}
        >
          Скачать
        </Button>
      </Box>
    </Form>
  );
};

export default DownloadCultivarFieldForm;
