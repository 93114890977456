import { IFilter } from "@/interfaces";
import { useQuery } from "@/hooks";
import {
  ICultivarFieldVarietyLabQueryParams
} from "@/pages/CultivarFieldVarietyLab/interfaces";
import {
  useCultivarFilter,
  useYearFilter,
  useCultivarFieldVarietyStatusFilter,
  useLaboratoryFilter
} from "@/hooks/filters";

const useFilters = (): IFilter[] => {

  const query = useQuery<ICultivarFieldVarietyLabQueryParams>();
  const cultivarFilter = useCultivarFilter({ selectedValue: query.cultivar });
  const yearFilter = useYearFilter({ selectedValue: query.year });
  const laboratoryFilter = useLaboratoryFilter({ selectedValue: query.branch });
  const statusFilter = useCultivarFieldVarietyStatusFilter({ selectedValue: query.status });

  const filters = [
    laboratoryFilter,
    yearFilter,
    cultivarFilter,
    statusFilter
  ];

  return filters;
};

export default useFilters;