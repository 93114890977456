import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import {
  CULTIVAR_FIELD_NAME,
  IProps,
  TForm,
  validationSchema,
} from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import Cultivar from "./components/CultivarForm";
import { SWR_KEYS } from "@/const";
import useSWR from "swr";
import { resInterface } from "@/api/interfaces";
import { convertStringToDate } from "../AddDistribution/utils";

/**
 * Модалка с формой создания/редактирования культуры
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const CultivarHandbook: React.FC<IProps> = (props) => {
  const { handleClose, modalProps } = props;
  const baseInititialValues = {
    is_active: undefined,
    name: undefined,
    latin_name: undefined,
    upov_code: undefined,
    is_protected_ground: false,
    list_type: "list_a",
    department: undefined,
    lab_sample_mass: undefined,
  };
  const { cultivarId, handleAccept } = modalProps;
  const { api, toastStore, swrStore } = useStores();

  let cultivarData:
    | resInterface.ICultivarHandbook
    | undefined;
  if (cultivarId) {
    const SWR_KEY = SWR_KEYS.getCultivarById(cultivarId);

    const { data } = useSWR(
      cultivarId ? SWR_KEY : null,
      () => api.regulatoryInfo.getCultivarById(cultivarId),
    );

    cultivarData = data;
  }

  const mutateDetail = cultivarId
    ? swrStore.mutators[
        SWR_KEYS.getCultivarById(cultivarId)
      ]
    : null;

  const mutateList =
    swrStore.mutators[SWR_KEYS.getCultivar()];

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const data = {
      ...values,
      department: values.department?.id,
    };

    const promise = cultivarId
      ? api.regulatoryInfo.patchCultivarByID(
          cultivarId,
          data,
        )
      : api.regulatoryInfo.postCultivar(data);

    promise
      .then((cultivar) => {
        handleAccept && handleAccept(cultivar);
        mutateDetail && mutateDetail();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail();
        mutateList && mutateList();
        handleClose();
      });
  };

  const values = modalProps.initialValues
    ? {
        ...baseInititialValues,
        ...cultivarData,
        [CULTIVAR_FIELD_NAME.RECEIPT_AT]: modalProps
          .initialValues.receipt_at
          ? convertStringToDate(
              modalProps.initialValues.receipt_at,
            )
          : undefined,
        [CULTIVAR_FIELD_NAME.DEPARTMENT]: {
          id: modalProps.initialValues.department?.id,
          label: modalProps.initialValues.department?.name,
        },
      }
    : baseInititialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => <Cultivar {...props} />}
    />
  );
};

export default CultivarHandbook;
