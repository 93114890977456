import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import { IProps, mapFormToValue, TForm, validationSchema } from "./const";
import { RateForm } from "./components/RateForm";


/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const Rate: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const baseInititialValues = {
      name: '',
      unit: null
  }
  const { api, toastStore, swrStore } = useStores();
  const { rateId } = modalProps;

  const mutateDetail = rateId ?
    swrStore.mutators[SWR_KEYS.getRateById(rateId)] : null

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const promise = rateId
    ? api.regulatoryInfo.patchRate(
      rateId,
      mapFormToValue(values),
    )
  : api.regulatoryInfo.postRate(mapFormToValue(values));

    promise
      .then(() => {
        mutateDetail && mutateDetail()
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail();
        swrStore.mutators[SWR_KEYS.getRates()]();
      });
  };

  const values = modalProps.initialValues
    ? {
      ...baseInititialValues,
      ...modalProps.initialValues,
      unit: {
        id: modalProps?.initialValues?.unit?.id, 
        name: modalProps?.initialValues?.unit?.name,
        label: modalProps?.initialValues?.unit?.name
      }
    }
    : baseInititialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <RateForm modalProps={modalProps} handleClose={handleClose} />
      )}
    />
  );
};

export default Rate;


