import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Table from "@/apps/Table";
import { observer } from "mobx-react";
import {
  useInfiniteScroll,
  useQuery,
  useStores,
} from "@/hooks";
import {
  IPaginatedResult,
  IQDList,
} from "@/api/interfaces/responses";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { SWR_KEYS } from "@/const";
import { IErrorResponse, IValue } from "@/interfaces";
import useSWR, { SWRResponse } from "swr";
import { BareFetcher, isUndefined } from "swr/_internal";
import {
  IPropsSidebar,
  TQuantityKeys,
} from "../../interfaces";
import AddIcon from "@mui/icons-material/Add";
import qs from "qs";
import Filters from "@/components/Filters";
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { resInterface } from "@/api/interfaces";
import { quantityColumns } from "../../models/quantity";
import { getFilters } from "./utils";
import { TYPES_ARRAY } from "./cells";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * Компонент, представляющий таблицу кол-ва в разнарядке
 * @returns {ReactElement}
 */
const QuantityTable: React.FC<IPropsSidebar> = ({
  handleOpenRightSidebar,
}): ReactElement => {
  const { api, swrStore, modalStore, userStore } = useStores();
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [selectedType, setSelectedType] = useState("");

  const [queryParams, setQueryParams] =
    useState<any>(query);
  // переменная которая показывает применены ли фильтры
  const isFilters: boolean =
    Object.keys(queryParams).length > 0;
  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orgeringArray);

  const {
    data,
    error,
    isLoading,
    mutate,
  }: SWRResponse<
    IPaginatedResult<resInterface.IQDList>,
    IErrorResponse
  > = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      updated_date_range_after:
        queryParams.date_range_after ?? undefined,
      updated_date_range_before:
        queryParams.date_range_before ?? undefined,
      search: queryParams.search,
      cultivar: queryParams.culture?.label ?? undefined,
      key: SWR_KEYS.getQuantityDistributionList(),
      type: selectedType,
    },
    api.regulatoryInfo
      .getQuantityDistributionList as BareFetcher<
      IPaginatedResult<IQDList>
    >,
  );

  const getCultivarKey = (index: number) => {
    return {
      key: SWR_KEYS.getCultivar(),
      page: index + 1,
      search: query.culture_type,
    };
  };

  const fetcherCultivars = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getQuantityDistributionCultivarList(
      {
        page,
        search,
      },
    );
  };

  const {
    data: cultivarsData,
    handleScroll: handleCultivarsScroll,
  } = useInfiniteScroll(getCultivarKey, fetcherCultivars, {
    revalidateFirstPage: false,
  });

  const cultivars: IValue[] = cultivarsData.map(
    ({ name, id }) => {
      return { label: name, key: id };
    },
  );

  const renderTypeMenuItems = TYPES_ARRAY.map((el) => {
    return (
      <MenuItem key={el.name} value={el.name}>
        <Box
          display={"flex"}
          gap={"10px"}
          alignItems={"center"}
        >
          <ListItemIcon>{el.icon}</ListItemIcon>
          <Typography color={el.textColor}>
            {el.label}
          </Typography>
        </Box>
      </MenuItem>
    );
  });

  const handleTypeSelectChange = (event) => {
    const queryParams = qs.stringify(
      { ...query, type: event.target.value, page: 1 },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setSelectedType(event.target.value);
  };

  const handleClearTypeSelect = () => {
    const queryParams = qs.stringify(
      { ...query, type: undefined },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setSelectedType("");
  };

  const filters = getFilters(
    [
      queryParams.date_range_after,
      queryParams.date_range_before,
    ],
    cultivars,
    queryParams.culture,
    renderTypeMenuItems,
    handleCultivarsScroll,
    handleTypeSelectChange,
    selectedType,
    handleClearTypeSelect,
  );

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleOpenCreateQuantityModal = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.QUANTITY, {
      theme: THEME_MODAL.W_555,
    });
  };

  const handleChangeOrderBy = (value) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  useEffect(() => {
    setQueryParams(query);
  }, [location.search]);

  swrStore.addMutator(
    SWR_KEYS.getQuantityDistributionList(),
    mutate,
  );

  const canAddQuantity = userStore.isPermission(
    PERMISSIONS.regulatory_infoQuantityDistributionList,
    PERMISSION_CRUD.add
  )

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
        />
        {canAddQuantity && 
        <Button
          sx={{
            whiteSpace: "nowrap",
            flexShrink: "0",
          }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenCreateQuantityModal}
        >
          Добавить запись
        </Button>
        }
      </Box>

      <Table<resInterface.IQDList, TQuantityKeys>
        columns={quantityColumns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={"Добавить запись"}
        textEmptyTableWithFilter={
          "Не найдено ни одной записи"
        }
        textEmptyTable={"Еще не создано ни одной записи"}
        onRowClick={handleOpenRightSidebar}
        emptyTableAddEntity={handleOpenCreateQuantityModal}
        overflowY="scroll"
        overflowX="scroll"
      />
    </Fragment>
  );
};

export default observer(QuantityTable);
