import React, { useState } from "react";
import { observer } from "mobx-react";
import { Field, Form, useFormikContext } from "formik";
import { Alert, Box, Button, FormControl, FormHelperText, Stack } from "@mui/material";
import {
  CultivarAutocomplete,
  RegionAutocomplete,
  BranchAutocomplete,
  PlanAutocomplete
} from "@/components/Autocompletes";
import { FIELDS_NAME, IFormik } from "../../const";
import CheckIcon from "@mui/icons-material/Check";
import { IRegionItem, } from "@/api/interfaces/responses";
import { YearDatePicker } from "@/components/Fields";
import { SWR_KEYS, TRIAL_TYPE } from "@/const";
import { useInfiniteScroll, useStores } from "@/hooks";
import Autocomplete from "@/components/Autocomplete";

const CultivarFieldVarietyLabForm = () => {
  const formik = useFormikContext<IFormik>();

  const handleChangeYear = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.YEAR, payload);
    void formik.setFieldValue(FIELDS_NAME.PLAN, "");
  };

  const handleChangeCultivar = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.CULTIVAR, payload);
    void formik.setFieldValue(FIELDS_NAME.PLAN, "");
  };

  const handleClearCultivar = () => {
    void formik.setFieldValue(FIELDS_NAME.CULTIVAR, "");
    void formik.setFieldValue(FIELDS_NAME.PLAN, "");
  };

  const handleChangeBranch = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, payload);
    void formik.setFieldValue(FIELDS_NAME.PLAN, "");
  };

  const handleClearBranch = () => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLAN, "");
  };

  const handleRegionClear = () => {
    void formik.setFieldValue(FIELDS_NAME.REGION, "");
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
  };

  const handleChangeRegion = (payload: IRegionItem) => {
    void formik.setFieldValue(FIELDS_NAME.REGION, payload);
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
  };

  const handleChange = (event, child) => {
    formik.setFieldValue(FIELDS_NAME.LABORATORY, child);
  };

  const { api } = useStores();
  
  const [search, setSearch] = useState("");
  
  const swrKey = SWR_KEYS.getLaboratoriesList();
  const getKeyOrganization = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    search: search,
    lab_plan: formik.values[FIELDS_NAME.PLAN]?.id ,
  });

  const { data, handleScroll, isEmpty } =
    useInfiniteScroll(
      getKeyOrganization,
      api.regulatoryInfo.getLaboratoriesList,
      {
        revalidateFirstPage: false,
      },
    );

  return (
    <Form noValidate>
      <Box p={3} sx={{ maxWidth: 350 }}>
        <Stack direction="column" gap="20px">
          <YearDatePicker name={FIELDS_NAME.YEAR}
            label="Год испытаний"
            handleChange={handleChangeYear}
            fullWidth />
          <CultivarAutocomplete
            name={FIELDS_NAME.CULTIVAR}
            handleChange={handleChangeCultivar}
            onClear={handleClearCultivar}
          />
          <RegionAutocomplete name={FIELDS_NAME.REGION}
            handleChange={handleChangeRegion}
            onClear={handleRegionClear}
          />
          <BranchAutocomplete name={FIELDS_NAME.BRANCH}
            q={{ region: formik.values.region?.id }}
            handleChange={handleChangeBranch}
            onClear={handleClearBranch}
          />
          <PlanAutocomplete name={FIELDS_NAME.PLAN} required
            q={{
              status: "approved",
              trial_type: TRIAL_TYPE.LAB,
              cultivar: formik.values.cultivar?.id,
              branch: formik.values.branch?.id,
              year_max: formik.values.year || undefined,
              year_min: formik.values.year || undefined
            }}
          />
          <Field name={FIELDS_NAME.LABORATORY}>
            {({ field, meta }) => (
              <FormControl required>
                <Autocomplete
                  {...field}
                  onChange={handleChange}
                  label={"Лаборатория"}
                  required={true}
                  data={data}
                  handleScroll={handleScroll}
                  search={setSearch}
                  error={meta.touched && !!meta.error}
                />

                {isEmpty && (
                  <Alert sx={{ mt: 2 }} severity="warning">
                    Для данного плана отсутствуют лаборатории
                  </Alert>
                )}

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Box display="flex" justifyContent="space-between"
          mt={5}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
            disabled={formik.isSubmitting}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default observer(CultivarFieldVarietyLabForm);
