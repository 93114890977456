import yup from "@/utils/yup";
import { IEntity } from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  TRIAL: "trial",
  STANDARD: "standard",
  STANDARDS: "standards",
} as const;

interface IValue extends IEntity {
  label: string;
}

export interface IFormik {
  [FIELDS_NAME.TRIAL]: IValue | null,
  [FIELDS_NAME.STANDARD]: IValue | null,
  [FIELDS_NAME.STANDARDS]?: IValue[] | null,
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.TRIAL]: yup.mixed().required(),
  [FIELDS_NAME.STANDARD]: yup.mixed().nullable()
});

export const initialValues = {
  [FIELDS_NAME.STANDARD]: null,
  [FIELDS_NAME.TRIAL]: null,
};

export const valueToForm = (payload: Partial<IFormik>) => {
  if (payload.standards) return {
    [FIELDS_NAME.TRIAL]: payload.trial ? {
      ...payload?.trial,
      label: payload?.trial?.name
    } : null,
    [FIELDS_NAME.STANDARD]: payload.standards.length ? {
      ...payload?.standards[0],
      label: payload?.standards[0]?.name
    } : null,
  }

  return {
    [FIELDS_NAME.TRIAL]: payload.trial ? {
      ...payload?.trial,
      label: payload?.trial?.name
    } : null,
    [FIELDS_NAME.STANDARD]: payload.standard ? {
      ...payload?.standard,
      label: payload?.standard?.name
    } : null,
  }
};