import React from "react";
import { Field, FieldArray } from "formik";
import { Box } from "@mui/system";
import { Stack, Button, IconButton, TextField, FormControlLabel, Checkbox } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  FIELDS_NAME,
  IFormik
} from "@/pages/AddObservation/const";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";
import {
  IndicatorMethodologyAutocomplete
} from "@/components/Autocompletes";
import IndicatorVariantMethodologyAutocomplete
  from "@/components/Autocompletes/IndicatorVariantMethodologyAutocomplete";
import theme from "@/theme";
import CloseIcon from "@mui/icons-material/Close";
import { TPlanningTrialType } from "@/api/types";
import { TRIAL_TYPE } from "@/const";
import IndicatorAutocomplete from "@/components/Autocompletes/Indicator";
import FastRegexTextField from "@/components/FastRegexTextField";

interface IProps {
  trialType?: TPlanningTrialType;
  isExpertReview?: boolean,
  cultivarId?: number,
}

const Indicators: React.FC<IProps> = ({ trialType, isExpertReview, cultivarId }) => {
  return (
    <Box>
      <Stack direction="column" spacing="20px">
        <Typography
          variant="h3">{trialType === TRIAL_TYPE.HP || trialType === TRIAL_TYPE.PHYTO || isExpertReview ? 'Параметры' : "Признаки"}</Typography>
        <FieldArray
          name={FIELDS_NAME.INDICATORS}
          render={(helpers) => {
            const values = helpers.form.values[helpers.name] as IFormik["indicators"];
            return (
              <Stack direction="column" spacing={1.2}>
                {values.map((value, index) => {
                  if (trialType === TRIAL_TYPE.OOS) {
                    return (
                      <Stack direction="column" key={index}
                        sx={{
                          padding: "10px",
                          border: `1px solid ${theme.palette.blackAndWhite.stroke}`
                        }}>
                        <Box mb="20px" display="flex"
                          justifyContent="space-between"
                          alignItems="center">
                          <Typography
                            variant="p14Medium">{`Признак ${index + 1}`}</Typography>
                          <IconButton
                            onClick={() => helpers.remove(index)}>
                            <CloseIcon
                              sx={{ color: theme.palette.red.main }} />
                          </IconButton>
                        </Box>
                        <Box mb="10px">
                          <IndicatorMethodologyAutocomplete
                            name={`${helpers.name}.${index}.${FIELDS_NAME.INDICATOR}`}
                            q={{ type_trial_indicator: trialType, cultivar: cultivarId }}
                            required />
                        </Box>
                        <Box mb="10px">
                          <IndicatorVariantMethodologyAutocomplete
                            name={`${helpers.name}.${index}.${FIELDS_NAME.INDICATOR_VARIANT}`}
                            q={{ id: value[FIELDS_NAME.INDICATOR]?.id }}
                            disabled={!value[FIELDS_NAME.INDICATOR]}
                            label="Степень выраженности"
                            required />
                        </Box>
                        <Field name={`${helpers.name}.${index}.${FIELDS_NAME.OFF_TYPE_PLANTS_NUMBER}`}>
                          {({ field, meta }) => (
                            <TextField
                              {...field}
                              type="number"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                              label={"Число нетипичных растений"}
                              fullWidth
                              size="small"
                            />
                          )}
                        </Field>
                        <Field type='checkbox' name={`${helpers.name}.${index}.${FIELDS_NAME.UNIFORMITY}`}>
                          {({ field }) => (
                            <FormControlLabel
                              {...field}
                              sx={{ width: 'fit-content' }}
                              control={<Checkbox />}
                              label='Однородность'
                            />
                          )}
                        </Field>
                      </Stack>
                    )
                  }
                  if (trialType === TRIAL_TYPE.HP || trialType === TRIAL_TYPE.PHYTO || isExpertReview) {
                    return (
                      <Stack direction="column" key={index}
                        sx={{
                          padding: "10px",
                          border: `1px solid ${theme.palette.blackAndWhite.stroke}`
                        }}>
                        <Box mb="20px" display="flex"
                          justifyContent="space-between"
                          alignItems="center">
                          <Typography
                            variant="p14Medium">{`Параметр ${index + 1}`}</Typography>
                          <IconButton
                            onClick={() => helpers.remove(index)}>
                            <CloseIcon
                              sx={{ color: theme.palette.red.main }} />
                          </IconButton>
                        </Box>
                        <Box mb="10px">
                          <IndicatorAutocomplete
                            label="Параметр"
                            name={`${helpers.name}.${index}.${FIELDS_NAME.INDICATOR}`}
                            q={{type_trial: TRIAL_TYPE.HP}}
                            required
                             />
                        </Box>
                        <FastRegexTextField name={`${helpers.name}.${index}.${FIELDS_NAME.INDICATOR_VALUE}`} label='Значение параметра' required />
                      </Stack>
                    )
                  }
                })}
                <Button
                  color={"blue"}
                  size={"large"}
                  startIcon={<AddOutlinedIcon />}
                  variant={"outlined"}
                  onClick={() => {
                    helpers.push({
                      [FIELDS_NAME.INDICATOR]: null,
                      [FIELDS_NAME.INDICATOR_VARIANT]: null,
                      [FIELDS_NAME.INDICATOR_VALUE]: null,
                      [FIELDS_NAME.OFF_TYPE_PLANTS_NUMBER]: null,
                      [FIELDS_NAME.UNIFORMITY]: null,
                    })
                  }
                  }
                >
                  {trialType ===  TRIAL_TYPE.PHYTO || trialType ===  TRIAL_TYPE.HP || isExpertReview ? 'Добавить параметр' : "Добавить признак"}
                </Button>
              </Stack>
            );
          }}
        />
      </Stack>
    </Box>
  );
};

export default Indicators;
