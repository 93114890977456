import React from "react";
import { AddEntity } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { PAGE_SIZE } from "@/const";
import {
  ICultivar,
  ICultivarHandbook
} from "@/api/interfaces/responses";
import { VARIANT_MODAL } from "@/apps/Modals/const";

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    key?:string
    initialValue?: ICultivar,
    onSubmit?: (value: ICultivar) => void
  };
}

/** Модалка с радио списком культур */

const SelectCultivar: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps
}) => {
  const { api, modalStore } = useStores();

  const handleAccept = (value: ICultivar) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handlePop();
  };
  const handleAcceptCultivar = (payload: ICultivarHandbook) => {
    modalStore.setModalPropsByKey(modalProps.key, {...modalProps, initialValue: payload})
  }
  const handleAdd = () => {
    modalStore.open(VARIANT_MODAL.CULTIVAR, { handleAccept: handleAcceptCultivar })
  }

  const fetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getCultivarList({
      page,
      page_size: PAGE_SIZE,
      search
    });
  };
  const getLabel = (value: ICultivar): string => value.name;

  const props = {
    isDisabledBtnAdd: true,
    ...modalProps,
    key: "GET_CULTIVAR",
    addButtonLabel: "Добавить культуру",
    title: "Культура",
    handleAccept,
    handleAdd,
    getLabel: getLabel,
    fetcher,
  };

  return (
    <AddEntity<ICultivar>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectCultivar;