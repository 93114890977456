import yup from "@/utils/yup";
import {
  IBranchItem,
  ICultivarUsageType,
  IEntity,
  IOrderCultivarGroup,
  IPlot,
  IRegionItem
} from "@/api/interfaces/responses";
import { Dayjs } from "dayjs";

export const FIELDS_NAME = {
  REGION: "region",
  BRANCH: "branch",
  PLOT: "plot",
  ORDER_CULTIVAR: "order_cultivar",
  CULTIVAR_GROUP: "cultivar_group",
  IS_HP: "is_hp",
  IS_OOS: "is_oos",
  IS_PHITO: "is_phyto",
  CULTIVAR_USAGE_TYPE: "cultivar_usage_type",
  TRIALS_TO: "trials_to",
  TRIALS_FROM: "trials_from",
  DISEASES: "diseases",
} as const;

export interface IFormik {
  [FIELDS_NAME.REGION]: IRegionItem,
  [FIELDS_NAME.BRANCH]: IBranchItem,
  [FIELDS_NAME.PLOT]: IPlot,
  [FIELDS_NAME.ORDER_CULTIVAR]: number,
  [FIELDS_NAME.CULTIVAR_GROUP]: IOrderCultivarGroup,
  [FIELDS_NAME.CULTIVAR_USAGE_TYPE]: ICultivarUsageType,
  [FIELDS_NAME.IS_HP]: boolean,
  [FIELDS_NAME.IS_OOS]: boolean,
  [FIELDS_NAME.IS_PHITO]: boolean,
  [FIELDS_NAME.TRIALS_FROM]: Dayjs | null,
  [FIELDS_NAME.TRIALS_TO]: Dayjs | null,
  [FIELDS_NAME.DISEASES]: IEntity[],
}

const isPhito = (() => {
  return {
    field: [FIELDS_NAME.IS_PHITO],
    sheme: {
      is: (data) => {return data},
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    },
  };
})();

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.PLOT]: yup.mixed().required(),
  [FIELDS_NAME.ORDER_CULTIVAR]: yup.number().required(),
  [FIELDS_NAME.CULTIVAR_GROUP]: yup.mixed().nullable(),
  [FIELDS_NAME.CULTIVAR_USAGE_TYPE]: yup.mixed().nullable(),
  [FIELDS_NAME.IS_HP]: yup.bool(),
  [FIELDS_NAME.IS_OOS]: yup.bool(),
  [FIELDS_NAME.IS_PHITO]: yup.bool(),
  [FIELDS_NAME.DISEASES]: yup.array().when(isPhito.field, ([isTrue], schema) => {
    return isTrue
      ? schema.required()
      : schema.nullable();
  }),
});
