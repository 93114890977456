import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { IProps, mapFormToValue, mapValueToForm, TForm, validationSchema } from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import DiseaseForm from "./components/ParameterForm";

const baseInitialValues: TForm = {
  unit: null,
  variety_trial_plot: null,
  year_of_testing: undefined,
  disease: null,
  disease_value: '',
};

/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */

const AddVarietyDisease: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {

  const { parameterId, initialValues, varietyId } = modalProps;
  const { api, toastStore, swrStore } = useStores();

  const mutateDetail = parameterId
    ? swrStore.mutators[
        SWR_KEYS.getIndicatorListItem(parameterId)
      ]
    : null;

  const mutateList =
    varietyId 
      ? swrStore.mutators[SWR_KEYS.getVarietyDiseaseList()] 
      : null;

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);
    const promise = parameterId
      ? api.regulatoryInfo.patchVarietyDiseaseById(
        mapFormToValue(values),
        parameterId,
        )
      : api.regulatoryInfo.postVarietyDisease(
          mapFormToValue(values, varietyId ),
        );

    promise
      .then(() => {
        mutateDetail && mutateDetail();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors.non_field_errors) {
          const errorMessage = errorToString(error);
          helpers.setFieldError('non_field_errors', errorMessage)
        }
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail?.();
        mutateList?.();
      });
  };

  const values = initialValues
    ? {
        ...baseInitialValues,
        ...mapValueToForm(initialValues),
      }
    : baseInitialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <DiseaseForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      )}
    />
  );
};

export default AddVarietyDisease;
