import yup from "@/utils/yup";
import { ILaboratory, IPlot } from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  LABORATORY: "laboratory",
  PLOTS: "plots",
} as const;

export interface IFormik {
  [FIELDS_NAME.LABORATORY]: ILaboratory,
  [FIELDS_NAME.PLOTS]: IPlot[]
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.LABORATORY]: yup.mixed().required(),
  [FIELDS_NAME.PLOTS]: yup.mixed()
});

export const initialValues = {
  [FIELDS_NAME.LABORATORY]: null,
  [FIELDS_NAME.PLOTS]: []
};

export interface IForm {
  laboratory: ILaboratory;
  plots: IPlot[];
}

export const serializedValues = (values) => ({
  ...initialValues,
  ...values,
})

export const ERROR_MESSAGE = "Не удалось создать лабораторию";
