import { IColumn } from "@/apps/Table/interfaces";
import { TRateKey } from "../interfaces";
import { RATE_KEYS, RATE_LABELS, } from "../const";
import {cellButtonsMaterialType,cellPointText, headerCellStyle } from "../style";
import { PointerTextCell } from "../cells";
import { EditingCellsRate, PointerTextCellName } from "../templates/Rate/cells";

export const COLUMN_EDITING = {
    value: "editing",
    label: "",
};

export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const RateColumns: IColumn<TRateKey>[] = [
    {
        label: RATE_LABELS.name,
        key: RATE_KEYS.name,
        contentHeader: RATE_LABELS.name,
        bodyCellProps: {
            render: PointerTextCell,
            styles: cellPointText,
        },
        headerCellProps: {
            styles: headerCellStyle,
        },
    },
    {
        label: RATE_LABELS.unit,
        key: RATE_KEYS.unit,
        contentHeader: RATE_LABELS.unit,
        bodyCellProps: {
            render: PointerTextCellName,
            styles: cellPointText,
        },
        headerCellProps: {
            styles: headerCellStyle,
        },
    },
    {
        key: "editing",
        label: RATE_LABELS.editing,
        contentHeader: COLUMN_EDITING.label,
        bodyCellProps: {
          render: EditingCellsRate,
          styles: cellButtonsMaterialType,
        },
    },
];