import React, { useEffect } from "react";
import qs from "qs";
import { AxiosError } from "axios";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  Box,
  IconButton,
  Menu,
  Typography,
  Divider,
} from "@mui/material";
import { IPlanning } from "@/api/interfaces/responses";
import {
  StyledMenuItem
} from "@/components/StyledMenuItem";
import { buttonDotsStyle } from "@/apps/Table/style";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DeleteIcon, EditIcon } from "@/components/icons";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import {
  CELL_TYPES
} from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { useQuery, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import {
  PLANNING_ENTITY_NAME,
  PLANNING_STATUS_NAME,
  EDITING_CELL
} from "@/pages/Planning/const";
import PlanningStatusChip
  from "@/components/Chips/PlanningStatusChip";
import {
  StyledTypography
} from "@/pages/ExpertAssessment/style";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * @component NumberCells
 * * компонент ячейки таблицы Филиала
 * @param
 */

export const NumberCells = (
  _: string,
  cell: IPlanning,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">
        {cell?.number || "-"}
      </Typography>
    </Box>
  );
};

/**
 * @component StatusCells
 * * компонент ячейки таблицы статуса
 * @param
 */

export const StatusCells = (
  _: string,
  cell: IPlanning,
): JSX.Element => {

  return (
    <PlanningStatusChip status={cell.status} />
  );
};

/**
 * @component CreatedAtCells
 * * компонент ячейки таблицы года испытания
 * @param
 */

export const CreatedAtCells = (
  _: string,
  cell: IPlanning,
): JSX.Element => {
  const date = dayjs(cell.created_at).format(
    "DD.MM.YYYY",
  );
  return (
    <Box>
      <Typography variant="body2">
        {date}
      </Typography>
    </Box>
  );
};

/**
 * @component CultivarCells
 * * компонент ячейки таблицы наименование культуры
 * @param
 */

export const CultivarCells = (
  _: string,
  cell: IPlanning,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">
        {cell?.cultivar?.name || "-"}
      </Typography>
    </Box>
  );
};

/**
 * @component BranchCells
 * * компонент ячейки таблицы наименование филиала
 * @param
 */

export const BranchCells = (
  _: string,
  cell: IPlanning,
): JSX.Element => {
  return (
    <Box>
      <StyledTypography variant="body2">
        {cell?.branch?.name || "-"}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCells = (
  _: string,
  data: IPlanning,
  itemsInPage: any[],
): JSX.Element => {
  const status = data.status;
  const {
    api,
    modalStore,
    swrStore,
    userStore,
  } = useStores();
  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else if (query?.last) {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  const mutateTable =
    swrStore.mutators[SWR_KEYS.getPlanningTable()];

  const mutateDetail = swrStore.mutators[SWR_KEYS.getPlanningByIdKey(data.id)]

  const mutateWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateTable && mutateTable();
    }
  };

  const handleDeletePlanning = (
    resolve: () => void,
    reject: (error: AxiosError) => void,
  ) => {
    api.planning
      .deletePlanningById(data.id)
      .then(() => {
        mutateWithCheckingLast();
        resolve();
      })
      .catch(reject)
      .finally(() => setAnchorEl(null));
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.universal,
      id: data.id,
      entityName: PLANNING_ENTITY_NAME,
      handleDelete: handleDeletePlanning,
    };

    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    const modalProp = {
      planning: data,
      handleSubmit: () => {
        mutateTable && mutateTable()
        mutateDetail && mutateDetail()
      }
    };
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.EDIT_PLANNING,
      modalProp,
    );
    handleClose(event)
  }

  const canEditPlan = userStore.isPermission(
    PERMISSIONS.planningEAPlan,
    PERMISSION_CRUD.change
  );

  const canDeletePlan = userStore.isPermission(
    PERMISSIONS.planningEAPlan,
    PERMISSION_CRUD.delete
  );

  if (status === PLANNING_STATUS_NAME.approved)
    return <></>;

  if (!canEditPlan && !canDeletePlan) return <></>;

  return (
    <Box>
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {canEditPlan &&
          <>
            <StyledMenuItem onClick={handleClickEdit}>
              <EditIcon />
              {EDITING_CELL.editing}
            </StyledMenuItem>
            <Divider />
          </>
        }

        {canDeletePlan &&
          <StyledMenuItem onClick={handleClickDelete}>
            <DeleteIcon color="error" />
            <Typography color="error">
              {" "}
              {EDITING_CELL.delete}
            </Typography>
          </StyledMenuItem>
          }

      </Menu>
    </Box>
  );
};
